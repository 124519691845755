import { ClientPaymentProcessorModel } from '@/models/client-payment-processor/client-payment-processor-model'

 import { ApiFactory } from '@/api/api-factory'

 const accountApi = ApiFactory.get('account')

export const ClientPaymentProcessorStore = {
    strict: process.env.NODE_ENV !== 'production',

    namespaced: true,

    state: {
        payments: null,
        paymentsLoaded: false,
    },

    actions: {
        async getContactPayment({ commit }, id) {

             let response = await accountApi.getContactPayment(id) 


             if (response && response.data) { 
                 let payments = new ClientPaymentProcessorModel(response.data)
                commit('SET_PAYMENTS', payments)
            } else {
                commit('SET_PAYMENTS', null)
            }
            commit('SET_PAYMENTS_LOADED', true)
        },

        async updateContactPayment({ commit }, payload) {
            let response = await accountApi.updateContactPayment(payload.id, payload.payment)
            return response.data
        }
    },

    mutations: {
        SET_PAYMENTS(state, payload) {
            state.payments = payload
        },
        SET_PAYMENTS_LOADED(state, payload) {
            state.paymentsLoaded = payload
        }
    },
}

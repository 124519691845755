import { ClientLiveLandingGroupModel } from './client-live-landing-group-model'
import { ClientLiveLandingQuestionModel } from './client-live-landing-question-model'

export class ClientLiveLandingModel {
	id = null

	name = null

	isEnabled = true

	isPolice = false

	enableGuidingQuestions = false

	enableJurisdiction = true
	jurisdiction = null

	pageTitle = {}
	pageDescription = {}

	landingPageStyle = null

	features = null

	groups = []

	currentQuestionIndex = -1

	_showPackages = false

	_selectedPackageId = null

	selectedPackage = null

	_showAllPackages = false

	questions = []

	constructor(entity) {

		this.id = entity.id

		this.name = entity.name

		this.isEnabled = entity.isEnabled

		this.isPolice = entity.isPolice

		this.enableGuidingQuestions = entity.enableGuidingQuestions

		this.enableJurisdiction = entity.enableJurisdiction
		this.jurisdiction = entity.jurisdiction

		this.pageTitle = entity.pageTitle
		this.pageDescription = entity.pageDescription

		this.landingPageStyle = entity.landingPageStyle || 'Simple'

		this.createdDate = entity.createdDate
		this.createdBy = entity.createdBy
		this.modifiedDate = entity.modifiedDate
		this.modifiedBy = entity.modifiedBy

		this.features = entity.features

		if (entity.groups) {
			entity.groups.filter(g => g.isEnabled).sort((a, b) => a.index - b.index).forEach(e => this.groups.push(new ClientLiveLandingGroupModel(e)))
		}

		if (entity.questions) {
			entity.questions.filter(q => q.isEnabled).sort((a, b) => a.index - b.index).forEach(e => this.questions.push(new ClientLiveLandingQuestionModel(e, this)))

			// Clear all options when class is first loaded.
			this.clearSelections()
		}

	}

	clearSelections() {
		this.questions.forEach(o => o.clearSelections())

		if (this.questions.length > 0) {
			this.questions[0].addTrigger("default")
        }
	}

	get showPackages() {
		return this.questions.some(q => q.isLastQuestion)
	}

	get isLoadingPackages() {
		return this.loadingPackages
    }

	get selectedPackageId() {
		return this._selectedPackageId
	}

	set selectedPackageId(value) {
		if (this._selectedPackageId == value) {
			return
		}

		this._selectedPackageId = value

		this.selectedPackage = value ? this.groups.flatMap(g => g.packages).find(p => p.id == value) : null
	}

	get showAllPackages() {
		return this._showAllPackages
	}

	set showAllPackages(value) {
		if (this._showAllPackages == value) {
			return
		}

		this._showAllPackages = value

		this.setAvailablePackages()
	}

	get hasSelectedOptions() {
		return this.questions.some(q => q.hasSelectedOptions)
	}

	clearSelectedPackage() {
		this.selectedPackageId = null
	}

	setAvailablePackages() {
		if (this.showAllPackages) {
			this.availablePackages = this.groups.flatMap(g => g.packages)
		} else {
			let packages = []

			this.groups.flatMap(g => g.packages).forEach(p => {
				p.isRecommended = true

				this.questions.filter(q => { return q.selectedOptionIds.length > 0 }).forEach((q) => {
					if (!q.selectedOptionIds.some(selectedId => p.questionOptions.includes(selectedId))) {
						p.isRecommended = false
					}
				})

				if (p.isRecommended) {
					packages.push(p)
				}
			})

			this.availablePackages = packages

			if (!this.availablePackages.find(p => p._selectedPackageId == p.id)) {
				this.selectedPackageId = null
			}
		}
	}

}
<template>
    <b-row class="mb-4">
        <b-col v-if="icon" cols="auto">
            <v-icon :icon="icon" style="margin-top: 0.25em; width: 40px; height: 40px;" class="text-black-50"></v-icon>
        </b-col>
        <b-col v-if="title && !subtitle" cols="auto" lg="5">
            <h4 v-if="title" style="line-height: 1.5em; margin-bottom: 0 !important;">{{ title }}</h4>
        </b-col>
        <b-col v-if="title && subtitle" cols="auto" lg="5">
            <h4 style="line-height: 1.2em; margin-bottom: 0 !important;">{{ title }}</h4>
            <h6 style="line-height: 1.2em; margin-bottom: 0 !important;">{{ subtitle }}</h6>
        </b-col>
        <b-col>
        </b-col>
        <b-col cols="auto">
            <slot v-if="!!$slots.default"></slot>
        </b-col>
        <b-col v-if="!!$slots.menu" cols="auto" class="text-right">
            <b-dropdown id="header-commands" variant="none" right toggle-class="btn-icon" no-caret>
                <template v-slot:button-content>
                    <v-icon icon="ellipsis-v" style="margin-top: 0.3em;" />
                </template>
                <div class="width-300">
                    <slot name="menu"></slot>
                </div>
            </b-dropdown>
        </b-col>
    </b-row>
</template>

<script>

    export default {
        name: 'view-header',

        props: {
            title: String,
            subtitle: String,
            icon: String
        }
    }

</script>
import { PageAuditModel } from './page-audit-model'
import { PageAuditGroupModel } from './page-audit-group-model'

export class PageAuditListModel {
    constructor(pageAudits) {
        this.pageAudits = []

        this.groups = []

        if (!pageAudits) {
            return
        }

        pageAudits.forEach(pageAudit => this.pageAudits.push(new PageAuditModel(pageAudit)))

        var dateStrings = new Set(this.pageAudits.map(pageAudit => pageAudit.dateString))

        dateStrings.forEach(dateString => {
            this.groups.push(new PageAuditGroupModel(dateString, this.pageAudits.filter(pageAudit => pageAudit.dateString === dateString)))
        })
    }

    get hasPageAudits() {
        return this.pageAudits.length > 0
    }
}
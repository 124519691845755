import { ClientLandingGroupPackageModel } from './client-landing-group-package-model'

import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export class ClientLandingGroupModel {
    id = null

    index = 0

    isEnabled = true

    enableJurisdiction = false

    resources = { }

    packages = []

    positions = null

    constructor(args) {
        const entity = args.entity

        if (entity) {
            this.id = entity.id
            this.index = entity.index
            this.isEnabled = entity.isEnabled

            this.enableJurisdiction = entity.enableJurisdiction

            this.resources = entity.resources

            if (entity.packages) {
                entity.packages.sort((a, b) => a.index - b.index).forEach(e => {
                    this.packages.push(new ClientLandingGroupPackageModel({ entity: e }))
                })
            }

            this.positions = entity.positions
        }
        else {
            this.id = uuidv4()

            this.resources = args.resources
        }

        // Add missing locales
        const locales = store.state.feature.features['App'].meta.locales

        if (locales) {
            locales.forEach(locale => {
                if (!this.resources[locale.id]) {
                    this.resources[locale.id] = { 'Name': '', 'Description': '' }
                }
            })
        }
    }

    reIndexPackages() {
        for (let i = 0; i < this.packages.length; i++) {
            this.packages[i].index = i
        }
    }

	addPackage(args) {
        this.packages.push(new ClientLandingGroupPackageModel(args))

		this.reIndexPackages()
	}

	deletePackage(id) {
		this.packages = this.packages.filter(f => f.id !== id)

		this.reIndexPackages()
	}
}
import Api from '../api'

export default {
    getPackages() {
        return Api.get('/PackageApi/PackageList')
    },
    getPackage(id) {
        let response = Api.get('/PackageApi/GetPackage?id=' + id)
        return response
    },
    getActiveServices() {
        let response = Api.get('/PackageApi/GetActiveServices')
        return response
    },

    addPackage(adminPackage) {
        return Api.post('/PackageApi/AddPackage', JSON.stringify(adminPackage))
    },

    updatePackage(adminPackage) {
        return Api.put(`/PackageApi/UpdatePackage/${adminPackage.PackageId}`, JSON.stringify(adminPackage))
    },

    deactivatePackage(adminPackage) {
        return Api.delete('/PackageApi/DeactivatePackage?id=' + adminPackage.id)
    }
}
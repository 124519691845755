
export default {
    install(Vue) {
        Vue.prototype.$downloadBase64 = function (fileName, base64) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(base64.split(',')[1])

            // separate out the mime component
            var mimeString = base64.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length)

            // create a view into the buffer
            var ia = new Uint8Array(ab)

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i)
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], { type: mimeString })

            this.$download(fileName, blob)
        }

        Vue.prototype.$download = function (fileName, blob) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE 11  TODO... Ie 11 isn't supported, so remove
                window.navigator.msSaveOrOpenBlob(blob, fileName)
            }
            else {
                var link = document.createElement('a')

                var url = URL.createObjectURL(blob)

                link.style = "display: none"
                link.href = url
                link.setAttribute('download', fileName)

                document.body.appendChild(link)

                link.click()

                URL.revokeObjectURL(link.href)

                link.remove()
            }
        }

    }
}

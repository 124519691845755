import { v4 as uuidv4 } from 'uuid'

import { merge } from 'lodash'

import store from '@/stores/store'

export class ClientApiPushModel {
    id = null

    isEnabled = true

    endpointUrl = null

    authenticationType = null

    username = null

    password = null

    format = null

    eivStatesForUpdate = []

    transactionStatesForUpdate = []

    constructor(args) {
        const entity = args ? args.entity : null

        if (entity) {
            this.id = entity.id

            this.isEnabled = entity.isEnabled

            this.endpointUrl = entity.endpointUrl

            this.authenticationType = entity.authenticationType

            this.username = entity.username

            this.password = entity.password

            this.format = entity.format

            this.eivStatesForUpdate = !Array.isArray(entity.eivStatesForUpdate) ? [] : entity.eivStatesForUpdate
            this.transactionStatesForUpdate = !Array.isArray(entity.transactionStatesForUpdate) ? [] : entity.transactionStatesForUpdate
        }
        else {
            this.id = uuidv4()

            const defaults = store.state.feature.features['Client'].meta.defaults

            if (defaults && defaults.api && defaults.api.push) {
                merge(this, defaults.api.push)
            }

            if (!Array.isArray(this.eivStatesForUpdate)) {
                this.eivStatesForUpdate = []
            }

            if (!Array.isArray(this.transactionStatesForUpdate)) {
                this.transactionStatesForUpdate = []
            }
        }
    }
}
import Account from './modules/account-api'
import ApplicationStatus from './modules/application-status-api'
import ClientInvoice from './modules/client-invoice-api'
import Content from './modules/content-api'
import Eiv from './modules/eiv-api'
import Feature from './modules/feature-api'
import FeatureEnableList from './modules/feature-enable-list-api'
import JobApi from './modules/job-api'
import Link from './modules/link-api'
import PublicApi from './modules/public-api'
import Session from './modules/session-api'
import ErrorLog from './modules/error-log-api'
import PageAudit from './modules/page-audit-api'
import AdminPackage from './modules/adminpackage-api'
import LoginMessage from './modules/login-message-api'
import EmailTemplates from './modules/email-templates-api'
import Reports from './modules/report-api'

const apis = {
    account: Account,
    applicationstatus: ApplicationStatus,
    clientinvoice: ClientInvoice,
    content: Content,
    eiv: Eiv,
    feature: Feature,
    featureenablelist: FeatureEnableList,
    jobs: JobApi,
    link: Link,
    public: PublicApi,
    session: Session,
    errorLog: ErrorLog,
    pageAudit: PageAudit,
    adminPackage: AdminPackage,
    loginMessage: LoginMessage,
    emailTemplates: EmailTemplates,
    reports: Reports
};

export const ApiFactory = {
    get: name => apis[name]
};

import { ClientLandingGroupModel } from './client-landing-group-model'
import { ClientLandingQuestionModel } from './client-landing-question-model'

import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export class ClientLandingModel {
	id = null

	name = null

	isEnabled = true

	isPolice = false

	enableGuidingQuestions = false

	enableJurisdiction = true
	jurisdiction = null

	pageTitle = {}
	pageDescription = {}

	landingPageStyle = 'Simple'

	features = { 'Application Status': true, 'Login': true }

	groups = []

	questions = []

	createdDate = null
	createdBy = null
	modifiedDate = null
	modifiedBy = null

	constructor(args) {

		const entity = args.entity

		if (entity) {
			this.id = entity.id

			this.name = entity.name

			this.isEnabled = entity.isEnabled

			this.isPolice = entity.isPolice

			this.enableGuidingQuestions = entity.enableGuidingQuestions

			this.enableJurisdiction = entity.enableJurisdiction
			this.jurisdiction = entity.jurisdiction

			// If an option already exists but doesn't have the pageTitle property, it needs to be added.
			// This will grab the default text from the Client feature and put that as pageTitle.
			if (!entity.pageTitle) {
				this.pageTitle = store.state.feature.features['Client'].meta.landing.pageTitle
			} else {
				this.pageTitle = entity.pageTitle
			}

			// If an option already exists but doesn't have the pageDescription property, it needs to be added.
			// This will grab the default text from the Client feature and put that as pageDescription.
			if (!entity.pageDescription) {
				this.pageDescription = store.state.feature.features['Client'].meta.landing.pageDescription
            } else {
				this.pageDescription = entity.pageDescription
            }

			this.landingPageStyle = entity.landingPageStyle || 'Simple'

			this.createdDate = entity.createdDate
			this.createdBy = entity.createdBy
			this.modifiedDate = entity.modifiedDate
			this.modifiedBy = entity.modifiedBy

			this.features = entity.features

			if (entity.groups) {
				entity.groups.sort((a, b) => a.index - b.index).forEach(e => this.groups.push(new ClientLandingGroupModel({ entity: e })))
			}

			if (entity.questions) {
				entity.questions.sort((a, b) => a.index - b.index).forEach(e => this.questions.push(new ClientLandingQuestionModel({ entity: e })))
			}
		}
		else {
			this.id = uuidv4()

			this.name = args.name

			// If there is no entity, this is a new landing page and we need to bring in the placeholders.
			this.pageDescription = store.state.feature.features['Client'].meta.landing.pageDescription
			this.pageTitle = store.state.feature.features['Client'].meta.landing.pageTitle

			this.createdDate = new Date().toISOString()
			this.createdBy = store.state.session.auth.identityId
		}

		// Add missing locales
		const locales = store.state.feature.features['App'].meta.locales

		if (locales) {
			locales.forEach(locale => {

				if (!this.pageTitle[locale.id]) {
					this.pageTitle[locale.id] = { 'Title': '' }
				}

				if (!this.pageDescription[locale.id]) {
					this.pageDescription[locale.id] = { 'Description': '' }
				}
			})
		}
	}

	reIndexGroups() {
		for (let i = 0; i < this.groups.length; i++) {
			this.groups[i].index = i
		}
	}

	addGroup(group) {
		this.groups.push(group)

		this.reIndexGroups()
	}

	deleteGroup(id) {
		this.groups = this.groups.filter(f => f.id !== id)

		this.reIndexGroups()
	}

	reIndexQuestions() {
		for (let i = 0; i < this.questions.length; i++) {
			this.questions[i].index = i
		}
	}

	addQuestion(question) {
		this.questions.push(question)

		this.reIndexQuestions()
	}

	deleteQuestion(id) {
		this.questions = this.questions.filter(f => f.id !== id)

		// Need to clear any nextAction field that had this question as the value
		this.clearDeletedQuestionFromNextActions(id)

		this.reIndexQuestions()
	}

	// If a question is deleted, it's ID needs to be cleared from any option that references it as a nextAction
	clearDeletedQuestionFromNextActions(questionId) {
		this.questions.forEach((q) => {
			q.removeDeletedQuestionFromNextAction(questionId)
		})
    }

	hasQuestionOption(id) {
		return this.questions.some(q => q.hasOption(id))
	}

	syncQuestionOptions() {
		this.groups.forEach(g => {
			g.packages.forEach(p => {
				const toDelete = p.questionOptions.filter(o => !this.hasQuestionOption(o))

				p.deleteQuestionOptions(toDelete)
			})
		})
	}
}
import Api from '../api'

export default {
    getAllLoginPageMessages() {
        var response = Api.get(`/LoginPageMessageApi/GetMessages`)
        return response
    },

    deleteLoginPageMessage(id) {
        var response = Api.delete(`/LoginPageMessageApi/DeleteMessage?id=${id}`)
        return response
    },

    updateLoginPageMessage(message) {
        return Api.post('/LoginPageMessageApi/UpdateMessage', JSON.stringify(message))
    },
}

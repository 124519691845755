<template>
    <div class="header-bar">
        <div v-if="theme.informationBarRaw">
            <div id="nav-informationbar" v-html="theme.informationBarRaw"></div>
        </div>
        <b-navbar toggleable="lg" type="dark" variant="primary" :class="{ 'box-shadow': !showShadowBar }">
            <b-navbar-brand><header-logo></header-logo></b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <header-navbar-main></header-navbar-main>
                <header-navbar-language v-if="!theme.disableLanguageToggle"></header-navbar-language>
                <header-navbar-user></header-navbar-user>
            </b-collapse>
        </b-navbar>
        <eiv-session-timeout v-if="eivId" :eivId="eivId"/>
        <div id="shadowbar" v-if="showShadowBar" :class="{ 'shadowing-bar': true, shadowing: auth.isShadowing, 'box-shadow': true }" style=""></div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    import HeaderLogo from './header-logo'
    import HeaderNavbarMain from './header-navbar-main'
    import HeaderNavbarLanguage from './header-navbar-language'
    import HeaderNavbarUser from './header-navbar-user'
    import EivSessionTimeout from '@/views/public/eiv/components/eiv-session-timeout'

    import Color from 'color'

    import { BNavbar, BNavbarBrand, BNavbarToggle } from 'bootstrap-vue'

    export default {
        name: 'header-bar',

        components: {
            HeaderLogo,
            HeaderNavbarMain,
            HeaderNavbarLanguage,
            HeaderNavbarUser,
            BNavbar,
            BNavbarBrand,
            BNavbarToggle,
            EivSessionTimeout
        },

        data() {
            return {
                eivId: null
            }
        },

        mounted() {
            this.isSessionExpired()
        },

        computed: {
            ...mapState('session', ['theme']),

            showShadowBar() {
                return this.auth.isShadowing || new Color(this.theme.colors.alternate).luminosity() < 1.0
            }
        },

        methods: {
            // Needed for reloading the page on expiration, otherwise the session timer starts over on the expired page.
            isSessionExpired() {
                let urlQuery = window.location.href
                if (urlQuery.indexOf("Expired") > -1) {
                    return
                } else {
                    this.getUserVerifiedId()
                }

            },
            // Find out if this is a candidate Eiv flow page by checking the url
            // for the userVerifiedId param and whether the session state is someone not authenticated.
            getUserVerifiedId() {
                let urlQuery = window.location.search
                let hasUserVerifiedId = new URLSearchParams(urlQuery).get('userVerifiedId')
                if (!this.auth.isAuthenticated && hasUserVerifiedId) {
                    this.eivId = hasUserVerifiedId
                }
            }
        }
    }
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingSupported
        ? _c("loading-component", {
            attrs: {
              active: _vm.isLoading,
              "can-cancel": false,
              "is-full-page": true
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isDevelopment
        ? _c("dev-tools", { attrs: { id: "devtools" } })
        : _vm._e(),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
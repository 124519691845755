import i18n from '@/resources/i18n'

import { en, fr } from 'vuejs-datepicker/dist/locale'

import moment from 'moment'

import { ApiFactory } from '@/api/api-factory'

const sessionApi = ApiFactory.get('session')

moment.suppressDeprecationWarnings = true;

export const ResourceStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        locales: null,
        currentLocale: window.initSession.locale,
        loadedComponentResources: [],

        vuejsDatepickerLanguage: en,

        useVueRouter: window.useVueRouter
    },

    actions: {
        initLocales({ commit, dispatch, state }, locales) {
            commit('SET_LOCALES', locales)

            dispatch('setVueLocale', state.currentLocale)
        },

        async setLocale({ commit, dispatch, state }, locale) {
            dispatch('setVueLocale', locale)

            await sessionApi.setLocale(locale, !state.useVueRouter)
        },

        setVueLocale({ commit, state }, locale) {
            commit('SET_LOCALE', locale)

            i18n.locale = state.currentLocale
            moment.locale(state.currentLocale)

            if (state.currentLocale === 'fr') {
                commit('SET_DATEPICKER_LOCALE', fr)
            }
            else {
                commit('SET_DATEPICKER_LOCALE', en)
            }
        },

        async loadComponentResources({ commit, state, dispatch }, name) {
            if (state.loadedComponentResources.includes(name)) {
                return
            }

            let response = await sessionApi.getComponentResources(name)

            var resources = response.data

            dispatch('mergeComponentResources', { name, resources })
        },

        mergeComponentResources({ state, commit }, resourceObj) {
            if (state.loadedComponentResources.includes(name)) {
                return
            }

            const resources = resourceObj.resources

            if (resources.en) {
                i18n.mergeLocaleMessage('en', resources.en)
            }
            if (resources.fr) {
                i18n.mergeLocaleMessage('fr', resources.fr)
            }

            commit('COMPONENT_RESOURCES_LOADED', resourceObj.name)
        }
    },

    mutations: {
        SET_LOCALES (state, locales) {
            state.locales = locales
        },

        SET_LOCALE (state, locale) {
            state.currentLocale = locale
        },

        SET_DATEPICKER_LOCALE (state, locale) {
            state.vuejsDatepickerLanguage = locale
        },

        COMPONENT_RESOURCES_LOADED(state, name) {
            state.loadedComponentResources.push(name)
        }
    }
}
import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export class ClientLandingGroupPackageModel {
	id = null

	packageId = null

	clientPackageId = null

	index = 0

	isEnabled = true

	enableJurisdiction = false

	resources = { }

	code = null

	startUrl = null

	questionOptions = []

	constructor(args) {
		const entity = args.entity

		if (entity) {
			this.id = entity.id
			this.packageId = entity.packageId
			this.clientPackageId = entity.clientPackageId
			this.index = entity.index
            this.isEnabled = entity.isEnabled

			this.enableJurisdiction = entity.enableJurisdiction

			this.resources = entity.resources

			this.code = entity.code

			this.startUrl = entity.startUrl

			if (entity.questionOptions) {
				this.questionOptions = entity.questionOptions
			}
		}
		else {
			this.id = uuidv4()

			this.resources = args.resources
		}

		// Add missing locales
		const locales = store.state.feature.features['App'].meta.locales

		if (locales) {
			locales.forEach(locale => {
				if (!this.resources[locale.id]) {
					this.resources[locale.id] = { 'Name': '' }
				}
			})
		}
	}

	hasQuestionOption(id) {
		return this.questionOptions.some(o => o == id)
	}

	addQuestionOption(id) {
		if (!this.hasQuestionOption(id)) {
			this.questionOptions.push(id)
		}
	}

	deleteQuestionOption(id) {
		this.questionOptions = this.questionOptions.filter(o => o !== id)
	}

	deleteQuestionOptions(ids) {
		this.questionOptions = this.questionOptions.filter(o => !ids.includes(o))
	}

	applyQuestionOptions(toAdd, toDelete) {
		if (toAdd) {
			toAdd.forEach(a => {
				this.addQuestionOption(a)
			})
		}

		if (toDelete && toDelete.length > 0) {
			this.deleteQuestionOptions(toDelete)
		}
	}
}
export const HeaderBarStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        headerBarLinks: null
    },

    actions: {
        setHeaderBarLinks({ commit }, headerBarLinks) {
            commit('SET_LINKS', headerBarLinks)
        },

        refreshHeaderBarLinks({ commit, state }) {
            commit('SET_LINKS', state.headerBarLinks)
        }
    },

    getters: {
        hasHeaderBarLinks(state) {
            return state.headerBarLinks && state.headerBarLinks.length > 0
        }
    },

    mutations: {
        SET_LINKS(state, headerBarLinks) {
            state.headerBarLinks = headerBarLinks
        }
    }
}
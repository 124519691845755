import { ApiFactory } from '@/api/api-factory'

import { PageAuditListModel } from '@/models/page-audits/page-audit-list-model'

const pageAuditApi = ApiFactory.get('pageAudit')

export const PageAuditStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        startUtc: null,
        endUtc: null,
        totalRecordCount: 0,
        page: 0,
        pageSize: 100,
        pageCount: 0,
        search: null,
        pageAudits: null
    },

    actions: {
        async loadPageAudits({ commit }, input) {
            let response = await pageAuditApi.getPageAudits(input)

            if (response.data) {
                commit('SET_AUDITS', response.data)
            }
            else {
                commit('SET_AUDITS', null)
            }
        },
    },

    mutations: {
        SET_AUDITS(state, data) {
            if (data) {
                state.startUtc = data.startUtc;
                state.endUtc = data.endUtc;
                state.totalRecordCount = data.totalRecordCount;
                state.page = data.page;
                state.pageSize = data.pageSize;
                state.pageCount = data.pageCount;
                state.search = data.search;

                state.pageAudits = new PageAuditListModel(data.records);
            } else {
                state.startUtc = null;
                state.endUtc = null;
                state.totalRecordCount = 0;
                state.page = 0;
                state.pageSize = 100;
                state.pageCount = 0;
                state.search = null;

                state.pageAudits = null;
            }
        },
    }
}


//import { Module, VuexModule, Mutation, Action, Getter } from 'vuex-module-decorators'

//import { ApiFactory } from '@/api/api-factory'

//const pageAuditApi = ApiFactory.get('pageAudit')

//@Module({ name: 'pageAudit' })
//export default class PageAuditStore extends VuexModule {
//	errors = [];

//	@Action({ commit: 'setErrors' })
//	async loadErrors(start, length, search) {
//		const response = await pageAuditApi.getErrors(start, length, search);

//		return response.data;
//	}

//	@Mutation
//	setErrors(errors) {
//		this.errors = errors;
//	}
//}
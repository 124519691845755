export class FileCategoryClientModel {
    selected = false

    constructor(fileClient, client, category) {
        if (fileClient) {
            this.fileCategoryClientId = fileClient.fileCategoryClientId
            this.fileCategoryId = fileClient.fileCategoryId
            this.clientId = fileClient.clientId
        }
        else {
            this.fileCategoryClientId = '00000000-0000-0000-0000-000000000000'
            this.fileCategoryId = category.fileCategoryId
            this.clientId = client.contactId
        }

        if (client) {
            this.client = client
        }
        else {
            this.client = { contactName: 'Unknown', userId: 'Unknown' }
        }
    }
}
export class ClientLiveLandingQuestionOptionModel {
	id = null

	index = 0

	isEnabled = true

	willEndQuestions = false

	willEndQuestionsMessage = {}

	nextAction = null

	forceSingleSelection = false

	resources = { }

	_isSelected = false

	parent = null

	_isLastQuestion = false

	constructor(entity, parent) {
		this.id = entity.id
		this.index = entity.index
		this.isEnabled = entity.isEnabled

		this.willEndQuestions = entity.willEndQuestions

		this.willEndQuestionsMessage = entity.willEndQuestionsMessage

		this.nextAction = entity.nextAction

		this.forceSingleSelection = entity.forceSingleSelection

		this.resources = entity.resources

		this.parent = parent
	}

	clearSelection() {
		this._isSelected = false
		this.parent.parent.setAvailablePackages() // This creates an availablePackages object from the start, for reactivity when options change later.
		this.toggleNextActionTrigger(this._isSelected)
	}

	get isSelected() {
		return this._isSelected
	}


	set isSelected(value) {
		if (value && this.forceSingleSelection) {
			this.parent.options.filter(o => o.id != this.id).forEach(o => o.clearSelection())
		} else if (value && !this.forceSingleSelection) {
			this.parent.options.filter(o => o.id != this.id && o.forceSingleSelection).forEach(o => o.clearSelection())
		}

		this._isSelected = value

		if (this._isSelected && this.willEndQuestions) {
			this.parent.options.filter(o => o.id != this.id).forEach(o => o.clearSelection())
		} else {
			this.toggleNextActionTrigger(this._isSelected)
			this.parent.parent.setAvailablePackages()
		}
	}

	toggleNextActionTrigger(on) {
		if (on) {
			if (this.nextAction && this.nextAction != 'willEndQuestions') {
				let index = this.parent.parent.questions.findIndex(q => q.id == this.nextAction)
				let nextQuestion = this.parent.parent.questions[index]
				if (nextQuestion)
					nextQuestion.addTrigger(this.id)
			}

			if (!this.nextAction || this.parent.index + 1 === this.parent.parent.questions.length) {
				let questions = this.parent.parent.questions
				let thisIndex = questions.findIndex(q => q.id == this.parent.id)
				var nextQuestion = null
				var lastQuestion = null
				questions.forEach((value, i) => {
					if (i == (thisIndex + 1)) {
						nextQuestion = value
					} else {
						// No i + 1 so this is the last question
						lastQuestion = value
                    }
                })
				if (nextQuestion) {
					nextQuestion.addTrigger(this.id)
				} else if (lastQuestion) {
					lastQuestion.setLastQuestion(true)
                }
					
			}
		} else {
			if (this.nextAction && this.nextAction != 'willEndQuestions' && this.parent.index + 1 != this.parent.parent.questions.length) {
				let index = this.parent.parent.questions.findIndex(q => q.id == this.nextAction)
				let nextQuestion = this.parent.parent.questions[index]
				if (nextQuestion)
					nextQuestion.removeTrigger(this.id)
			}

			if (!(this.nextAction)) {
				let thisIndex = this.parent.parent.questions.findIndex(q => q.id == this.parent.id)
				let nextQuestion = this.parent.parent.questions[thisIndex + 1]
				if (nextQuestion) {
					nextQuestion.removeTrigger(this.id)
				}
			}
        }
    }
}
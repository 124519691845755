import { FileCategoriesListModel } from '@/models/files/file-categories-list-model'

import { ApiFactory } from '@/api/api-factory'

const contentApi = ApiFactory.get('content')

export const ContentStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        maxFileSize: 20971520,
        fileCategoriesLoaded: false,
        fileCategoriesList: null
    },

    actions: {
        async getMaxFileSize({ commit }) {
            let response = await contentApi.getMaxFileSize()

            commit('SET_MAX_FILE_SIZE', response.data)
        },

        async getContentCategories ({ commit }) {
            let response = await contentApi.getContentCategories()

            if (response.data && (Array.isArray(response.data) || Object.keys(response.data).length !== 0)) {
                commit('SET_FILE_CATEGORIES', response.data)
            }
            else {
                commit('SET_FILE_CATEGORIES', null)
            }

            commit('SET_FILE_CATEGORIES_LOADED', true)
        },

        async addCategory({ commit }, category) {
            let response = await contentApi.addCategory(category)

            commit('ADD_CATEGORY', response.data)
        },

        async updateCategory({ commit }, category) {
            let response = await contentApi.updateCategory(category)

            commit('UPDATE_CATEGORY', response.data)
        },

        async deleteCategory({ commit }, category) {
            await contentApi.deleteCategory(category)

            commit('REMOVE_CATEGORY', category)
        },

        async addFile({ commit }, file) {
            let response = await contentApi.addFile(file)

            commit('ADD_FILE', response.data)
        },

        async updateFile({ commit }, file) {
            let response = await contentApi.updateFile(file)

            commit('UPDATE_FILE', response.data)
        },
        
        async deleteFile({ commit }, file) {
            await contentApi.deleteFile(file)

            commit('REMOVE_FILE', file)
        },

        async downloadFile({ }, file) {
            let response = await contentApi.downloadFile(file)

            return response.data
        }
    },

    mutations: {
        SET_MAX_FILE_SIZE (state, maxFileSize) {
            state.maxFileSize = maxFileSize
        },

        SET_FILE_CATEGORIES(state, fileCategories) {
            state.fileCategoriesList = new FileCategoriesListModel(fileCategories)
        },

        SET_FILE_CATEGORIES_LOADED(state, loaded) {
            state.fileCategoriesLoaded = loaded
        },

        ADD_CATEGORY(state, category) {
            if (!state.fileCategoriesLoaded) {
                return
            }

            state.fileCategoriesList.addCategory(category)
        },

        UPDATE_CATEGORY(state, category) {
            if (!state.fileCategoriesLoaded) {
                return
            }

            let fileCategory = state.fileCategoriesList.getFileCategory(category.fileCategoryId)

            fileCategory.updateValues(category)
        },

        REMOVE_CATEGORY(state, category) {
            if (!state.fileCategoriesLoaded) {
                return
            }

            state.fileCategoriesList.removeCategory(category.fileCategoryId)
        },

        ADD_FILE(state, file) {
            if (!state.fileCategoriesLoaded) {
                return
            }

            let fileCategory = state.fileCategoriesList.getFileCategory(file.fileCategoryId)

            fileCategory.addFile(file)
        },

        UPDATE_FILE(state, file) {
            if (!state.fileCategoriesLoaded) {
                return
            }

            let fileCategory = state.fileCategoriesList.getFileCategory(file.fileCategoryId)

            let concretefile = fileCategory.getFile(file.contentItemId)

            concretefile.updateValues(file)
        },

        REMOVE_FILE(state, file) {
            if (!state.fileCategoriesLoaded) {
                return
            }

            let fileCategory = state.fileCategoriesList.getFileCategory(file.fileCategoryId)

            fileCategory.removeFile(file.contentItemId)
        }
    }
}
import { render, staticRenderFns } from "./dev-tools.vue?vue&type=template&id=313314ed&"
import script from "./dev-tools.vue?vue&type=script&lang=js&"
export * from "./dev-tools.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./dev-tools.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\TritonPresentation\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('313314ed')) {
      api.createRecord('313314ed', component.options)
    } else {
      api.reload('313314ed', component.options)
    }
    module.hot.accept("./dev-tools.vue?vue&type=template&id=313314ed&", function () {
      api.rerender('313314ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/app/components/dev-tools.vue"
export default component.exports
export class ClientPaymentProcessorModel {
    Inherited = false
    PaymentProviders = []
    OverriddenConfiguration = null
    InheritedConfiguration = null

    constructor(payment) {

        if (payment) {
            this.Inherited = payment.Inherited
            this.PaymentProviders = payment.PaymentProviders
            this.OverriddenConfiguration = payment.OverriddenConfiguration
            this.InheritedConfiguration = payment.InheritedConfiguration
        }
    }
}

import { ErrorLogModel } from './error-log-model'
import { ErrorLogGroupModel } from './error-log-group-model'

export class ErrorLogListModel {
    constructor(errorLogs) {
        this.errorLogs = []

        this.groups = []

        if (!errorLogs) {
            return
        }

        errorLogs.forEach(errorLog => this.errorLogs.push(new ErrorLogModel(errorLog)))

        var dateStrings = new Set(this.errorLogs.map(errorLog => errorLog.dateString))

        dateStrings.forEach(dateString => {
            this.groups.push(new ErrorLogGroupModel(dateString, this.errorLogs.filter(errorLog => errorLog.dateString === dateString)))
        })
    }

    get hasErrorLogs() {
        return this.errorLogs.length > 0
    }
}
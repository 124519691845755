<template>
    <div>
        <b-row class="text-center mt-5">
            <b-col>
                <v-icon icon="plug" class="text-secondary" style="width: 48px; height: 48px;"></v-icon>
            </b-col>
        </b-row>

        <b-row class="text-center mt-5">
            <b-col>
                <h4>{{ $t('Standard.Disabled') }}</h4>
            </b-col>
        </b-row>

        <b-row class="text-center mt-4">
            <b-col>
                <label class="text-secondary mt-2" style="width: 700px">{{ $t('Standard.DisabledMessage') }}</label>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: 'disabled-view',

        mounted() {
            this.setIsLoadingTheme(false)
        }
    }
</script>

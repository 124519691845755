var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.dev-modal",
              modifiers: { "dev-modal": true }
            }
          ],
          staticClass: "btn-icon",
          attrs: { title: _vm.$t("DevTools.DevTools"), variant: "light" }
        },
        [_c("v-icon", { attrs: { icon: "tools" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "dev-modal",
          attrs: {
            id: "dev-modal",
            title: _vm.$t("DevTools.DevTools"),
            centered: "",
            "hide-header-close": "",
            "ok-only": ""
          }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "checkbox-autosessionchecks" },
                      model: {
                        value: _vm.isAutoResetTimeoutNextTick,
                        callback: function($$v) {
                          _vm.isAutoResetTimeoutNextTick = $$v
                        },
                        expression: "isAutoResetTimeoutNextTick"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("DevTools.AutomateSessionChecks")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { id: "checkbox-showbackground" },
                      model: {
                        value: _vm.isShowingBackground,
                        callback: function($$v) {
                          _vm.isShowingBackground = $$v
                        },
                        expression: "isShowingBackground"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("DevTools.ShowBackground")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.auth.isAuthenticated
    ? _c(
        "b-navbar-nav",
        { staticClass: "nav-language" },
        [
          _c(
            "b-nav-item-dropdown",
            {
              class: { shadowing: _vm.auth.isShadowing },
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function() {
                      return [
                        _c("v-icon", { attrs: { icon: "user" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2 mr-2",
                            attrs: { id: "nav-item-identityfullname" }
                          },
                          [_vm._v(_vm._s(_vm.userIdConsideringShadowing))]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2949056970
              )
            },
            [
              _vm._v(" "),
              _vm.auth.isShadowing
                ? _c(
                    "div",
                    { staticClass: "width-300" },
                    [
                      _c(
                        "b-dropdown-text",
                        { attrs: { id: "nav-item-shadowid" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("HeaderBar.ShadowID")) +
                              ": " +
                              _vm._s(_vm.auth.shadowId)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-text",
                        { attrs: { id: "nav-item-shadowclient" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("HeaderBar.ShadowClient")) +
                              ": " +
                              _vm._s(_vm.auth.shadowClient)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-divider"),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { id: "nav-item-unshadow" },
                          on: { click: _vm.unshadow }
                        },
                        [_vm._v(_vm._s(_vm.$t("HeaderBar.StopShadowing")))]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-divider")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin
                ? _c(
                    "div",
                    { staticClass: "width-300" },
                    [
                      _c(
                        "b-dropdown-text",
                        { attrs: { id: "nav-item-identityid" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("HeaderBar.LoginID")) +
                              ": " +
                              _vm._s(_vm.auth.identityId)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-text",
                        { attrs: { id: "nav-item-build" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("HeaderBar.Build")) +
                              ": " +
                              _vm._s(_vm.menu.build)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-text",
                        { attrs: { id: "nav-item-release" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("HeaderBar.Release")) +
                              ": " +
                              _vm._s(_vm.menu.release)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-text",
                        { attrs: { id: "nav-item-machinename" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("HeaderBar.MachineName")) +
                              ": " +
                              _vm._s(_vm.machineName)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-divider")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { attrs: { id: "nav-item-logoff" }, on: { click: _vm.logoff } },
                [_vm._v(_vm._s(_vm.$t("HeaderBar.LogOff")))]
              )
            ],
            1
          )
        ],
        1
      )
    : _c("b-navbar-nav", { staticClass: "nav-language" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
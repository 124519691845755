import { ApiFactory } from '@/api/api-factory'

const api = ApiFactory.get('featureenablelist')

export const FeatureEnableListStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        features: [],
        workQueueTypes: [],
    },

    actions: {
        async getFeatureEnableList({ commit, state }) {
            let response = await api.getFeaturesEnabled()
            if (response.data) 
                commit('SET_FEATURES', response.data)
        },

        async getWorkQueueTypeEnableList({ commit, state }) {
            let response = await api.getWorkQueueTypesEnabled()
            if (response.data) 
                commit('SET_WORKQUEUETYPES', response.data)
        },

        async featureEnableEdit({ dispatch, state }, { feature }) {
            let response = await api.featureEnableEdit(feature)
            if (response.data) {
                await dispatch('getFeatureEnableList')
            }
            return response.data
        },

        async workQueueTypeEnableEdit({ dispatch, state }, { workQueueType }) {
            let response = await api.workQueueTypeEnableEdit(workQueueType)
            if (response.data) {
                await dispatch('getWorkQueueTypeEnableList')
            }
            return response.data
        },

        async testWorkQueueType({ commit, state }, workQueueTypeId) {
            let response = await api.testWorkQueueType(workQueueTypeId)
            const data = response.data
            return data;
        }

    },

    mutations: {
        SET_FEATURES(state, features) {
            state.features = features
        },

        SET_WORKQUEUETYPES(state, workQueueTypes) {
            state.workQueueTypes = workQueueTypes
        },
    }
}
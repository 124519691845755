<template>
    <div>
        <loading-component v-if="loadingSupported"
                           :active.sync="isLoading"
                           :can-cancel="false"
                           :is-full-page="true">
        </loading-component>
        <session-timeout v-if="auth.isAuthenticated"></session-timeout>
        <dev-tools id="devtools" v-if="isDevelopment"></dev-tools>
        <div>
            <!--<mvc-header-bar></mvc-header-bar>-->
            <b-container v-if="useVueRouter">
                <router-view class="pt-4 pb-4"></router-view>
            </b-container>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import store from '@/stores/store'
    import i18n from '@/resources/i18n'

    import { mapState, mapGetters, mapActions } from 'vuex'

    import ViewFactory from './router/view-factory'

    import LoadingComponent from 'vue-loading-overlay'

    import 'vue-loading-overlay/dist/vue-loading.css'

    import DevTools from './components/dev-tools'
    import MvcHeaderBar from './components/header-bar/header-bar'

    const SessionTimeout = () => ViewFactory.get('private/components/session-timeout')

    export default {
        components: {
            LoadingComponent,
            SessionTimeout,
            MvcHeaderBar,
            DevTools
        },

        methods: {
            ...mapActions('session', ['initSession']),

            ...mapActions('feature', ['initApp', 'getFeature']),

            ...mapActions('headerbar', ['setHeaderBarLinks']),

            setBackground() {
                document.body.style.backgroundImage = !this.isProduction && !this.hideBackground ? `url('/content/images/${this.environment}EnvBackground.png')` : ''
                document.body.style.backgroundRepeat = !this.isProduction && !this.hideBackground ? 'repeat-x repeat-y' : ''
            }
        },

        computed: {
            ...mapState('session', ['auth', 'hideBackground'])
        },

        watch: {
            hideBackground() {
                this.setBackground()
            }
        },

        data() {
            return {
                loadingSupported: !window.isIE,
                //mvcComponentInjections: [
                //    {
                //        name: 'mvc-header-bar',
                //        id: 'tempheader',
                //        component: () => ViewFactory.get('private/clients/components/customer-landing/customer-landing-config'),
                //        feature: 'Users',
                //        props: [
                //            { 
                //                name: 'contactid', 
                //                type: Number
                //            },
                //            {
                //                name: 'isnew',
                //                type: Boolean
                //            }
                //        ]
                //    }
                //]
            }
        },

        async created() {
            await this.initApp()

            const feature = await this.getFeature('Private')

            this.setHeaderBarLinks(feature.meta.headerBar)

            const elem = document.querySelector('#mvcheaderbar')

            if (elem) {
                const headerBarClass = Vue.extend(MvcHeaderBar)

                new headerBarClass({
                    store,
                    i18n
                }).$mount('#mvcheaderbar')
            }

            this.setIsLoadingTheme(false)

            this.setBackground()
        },

        // DOESN'T WORK QUITE WELL ENOUGH... Going with alterate solution -Nolan
        //async mounted() {
        //    this.mvcComponentInjections.forEach(ci => {
        //        const elem = document.getElementById(ci.id)

        //        if (elem) {
        //            ci.component().then(async module => {
        //                if (ci.feature) {
        //                    await this.getFeature(ci.feature)
        //                }

        //                let dataset = document.querySelector('#' + ci.id).dataset

        //                let propsData = {  }

        //                if (ci.props) {
        //                    ci.props.forEach(p => {
        //                        propsData[p.name] = p.type(dataset[p.name])
        //                    })
        //                }

        //                const componentCtor = Vue.extend(module.default)

        //                const componentInstance = new componentCtor({
        //                    propsData
        //                })

        //                componentInstance.$mount(`#${ci.id}`)
        //            })
        //        }
        //    })
        //},

        async errorCaptured(err, vm, info) {
            // err: error trace
            // vm: component in which error occured
            // info: Vue specific error information such as lifecycle hooks, events etc.
            // TODO: Perform any custom logic or log to server
            // return false to stop the propagation of errors further to parent or global error handler

            this.setIsLoading(false);

            const errorLogId = await this.logError(err);

            const subUrl = this.isAdmin ? `error?errorLogId=${errorLogId}` : 'error';

            window.location = window.baseUrl + (this.auth.isAuthenticated ? `/v/private/${subUrl}` : `/v/public/${subUrl}`);
        }
    }
</script>
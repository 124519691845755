export const SideBarStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        sideBarLinks: null
    },

    actions: {
        setSideBarLinks({ commit }, sideBarLinks) {
            commit('SET_LINKS', sideBarLinks)
        }
    },

    getters: {
        hasSideBarLinks(state) {
            return state.sideBarLinks && state.sideBarLinks.length > 0
        }
    },

    mutations: {
        SET_LINKS(state, sideBarLinks) {
            state.sideBarLinks = sideBarLinks
        }
    }
}
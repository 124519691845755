export function addYears(date, years) {
    date.setFullYear(date.getFullYear() + years)
    return date
}

export function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60 * 1000)
}

export function dateDiffSeconds(date) {
    return Math.round((date - (new Date()).getTime()) / 1000)
}
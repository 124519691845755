<template>
    <b-navbar-nav v-if="auth.isAuthenticated" class="nav-language">
        <b-nav-item-dropdown :class="{ shadowing: auth.isShadowing }" right>
            <template v-slot:button-content>
                <v-icon icon="user"></v-icon>
                <span id="nav-item-identityfullname" class="ml-2 mr-2">{{ userIdConsideringShadowing }}</span>
            </template>
            <div v-if="auth.isShadowing" class="width-300">
                <b-dropdown-text id="nav-item-shadowid">{{ $t('HeaderBar.ShadowID') }}: {{ auth.shadowId }}</b-dropdown-text>
                <b-dropdown-text id="nav-item-shadowclient">{{ $t('HeaderBar.ShadowClient') }}: {{ auth.shadowClient }}</b-dropdown-text>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item id="nav-item-unshadow" @click="unshadow">{{ $t('HeaderBar.StopShadowing') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
            </div>
            <div v-if="isAdmin" class="width-300">
                <b-dropdown-text id="nav-item-identityid">{{ $t('HeaderBar.LoginID') }}: {{ auth.identityId }}</b-dropdown-text>
                <b-dropdown-text id="nav-item-build">{{ $t('HeaderBar.Build') }}: {{ menu.build }}</b-dropdown-text>
                <b-dropdown-text id="nav-item-release">{{ $t('HeaderBar.Release') }}: {{ menu.release }}</b-dropdown-text>
                <b-dropdown-text id="nav-item-machinename">{{ $t('HeaderBar.MachineName') }}: {{ machineName }}</b-dropdown-text>
                <b-dropdown-divider></b-dropdown-divider>
            </div>
            <b-dropdown-item id="nav-item-logoff" @click="logoff">{{ $t('HeaderBar.LogOff') }}</b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-navbar-nav v-else class="nav-language">
        <!--<b-nav-form>
            <b-button v-if="isLoginEnabled" id="nav-item-login" variant="outline-light" class="btn-whitelabel ml-2 mr-2" @click="login" size="sm">{{ $t('HeaderBar.Login') }}</b-button>
        </b-nav-form>-->
    </b-navbar-nav>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    import { BNavbarNav, BNavItemDropdown, BNavForm } from 'bootstrap-vue'

    export default {
        name: 'header-navbar-user',

        components: {
            BNavbarNav,
            BNavItemDropdown,
            BNavForm
        },

        methods: {
            ...mapActions('session', ['login', 'logoff', 'unshadow']),

            ...mapActions('resource', ['setLocale'])
        },

        computed: {
            ...mapState('session', ['menu', 'machineName']),

            ...mapGetters('session', ['isAdmin', 'userIdConsideringShadowing']),

            ...mapGetters('public', ['isLoginEnabled'])
        }
    }
</script>
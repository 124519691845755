export class FileDataModel {
    constructor(fileData) {
        if (fileData) {
            this.fileDataId = fileData.fileDataId
            this.fileId = fileData.fileId
            this.data = fileData.data
        }
        else {
            this.fileDataId = '00000000-0000-0000-0000-000000000000'
            this.fileId = null
            this.data = null
        }
    }
}

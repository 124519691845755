<template>
    <div>
        <b-modal ref="evite-timeout-warning-modal"
                 centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-header-close
                 @ok="handleWarningOk">
            <div class="text-center">
                <b-alert show variant="danger" class="mb-0">
                    <div class="message">
                        <p v-html="remainingTimeMessage"></p>
                    </div>
                    <div>{{ $t('SessionTimeout.action') }}</div>
                    <b-progress variant="danger" height="4px" class="mt-2"
                                :max="sessionWarningPeriodLength"
                                :value="sessionRemainingTime">
                    </b-progress>
                </b-alert>
            </div>

            <template v-slot:modal-footer="{ ok }">
                <b-container>
                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <b-button id="button-sessiontimeout-continue" variant="secondary" @click="ok()">
                                {{ $t('SessionTimeout.continue') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        props: ['eivId'],

        computed: {
            ...mapState('eivSession', ['sessionRemainingTime', 'sessionWarningPeriodLength']),

            ...mapGetters('eivSession', ['getShowWarning', 'getShowRedirect']),

            remainingTimeMessage() {
                var minutes = (this.sessionRemainingTime / 60) | 0
                var seconds = (this.sessionRemainingTime % 60) | 0

                seconds = seconds < 10 ? "0" + seconds : seconds

                var remainingTimeDisplay = (minutes > 0 ? minutes + 'm ' : '') + seconds + 's'

                return this.$i18n.t('SessionTimeout.message', { 'remainingTime': remainingTimeDisplay })
            }
        },

        async mounted() {
            await this.startExpiryTimer()
        },

        watch: {
            getShowWarning(newValue) {
                if (newValue) {
                    this.$refs['evite-timeout-warning-modal'].show()
                }
                else {
                    this.$refs['evite-timeout-warning-modal'].hide()
                }
            },

            getShowRedirect(newValue) {
                if (newValue) {
                    this.endSession()
                }
            }
        },

        methods: {

            ...mapActions('eivSession', ['getExpiryTime', 'resetCandidateExpiryTime', 'endSession', 'logError']),

            async startExpiryTimer() {
                try {
                    await this.resetCandidateExpiryTime(this.eivId)
                } catch (error) {
                    this.logError(error)
                }
            },

            async handleWarningOk() {
                this.resetCandidateExpiryTime(this.eivId)
            }
        },
    }
</script>

<i18n>
    {
        "en": {
            "SessionTimeout": {
                "action": "Press continue to keep this session active",
                "continue": "Continue",
                "message": "Your session will end in <span>{remainingTime}</span> due to inactivity."
            }
        },
        "fr": {
            "SessionTimeout": {
                "action": "Appuyez sur continuer pour garder cette session active.",
                "continue": "Continuez",
                "message": "Votre session se terminera par <span>{remainingTime}</span> pour cause d'inactivité."
            }
        }
    }
</i18n>

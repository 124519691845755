import Api from '../api'

export default {
    getFeaturesEnabled() {
        return Api.get('/InternalToolsApi/GetFeaturesEnabled')
    },
    getWorkQueueTypesEnabled() {
        return Api.get('/InternalToolsApi/GetWorkQueueTypesEnabled')
    },
    featureEnableEdit(model) {
        return Api.post(`/InternalToolsApi/FeatureEnableEdit`, JSON.stringify(model))
    },
    workQueueTypeEnableEdit(model) {
        return Api.post(`/InternalToolsApi/WorkQueueTypeEnableEdit`, JSON.stringify(model))
    },
    testWorkQueueType(id) {
        return Api.post(`/InternalToolsApi/TestWorkQueueType?id=${id}`)
    }
}
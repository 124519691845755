var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "evite-timeout-warning-modal",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header": "",
            "hide-header-close": ""
          },
          on: { ok: _vm.handleWarningOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var ok = ref.ok
                return [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    id: "button-sessiontimeout-continue",
                                    variant: "secondary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return ok()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$t("SessionTimeout.continue")
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "b-alert",
                { staticClass: "mb-0", attrs: { show: "", variant: "danger" } },
                [
                  _c("div", { staticClass: "message" }, [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.remainingTimeMessage) }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.$t("SessionTimeout.action")))]),
                  _vm._v(" "),
                  _c("b-progress", {
                    staticClass: "mt-2",
                    attrs: {
                      variant: "danger",
                      height: "4px",
                      max: _vm.sessionWarningPeriodLength,
                      value: _vm.sessionRemainingTime
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
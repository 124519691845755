export class EmailedRequestModel {
    id = null
    date = null
    from = null
    pageCount = null

    constructor(request) {
        if (request) {
            this.id = request.requestId.toString()
            this.date = new Date(request.dateReceived)
            this.from = request.fromEmail
            this.pageCount = request.numDocs
        }
    }
}
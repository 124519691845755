import { PageAuditModel } from '../page-audits/page-audit-model'

export class ErrorLogModel {
    isExpanded = false;

    constructor(errorLog) {
        this.id = errorLog.id;

        this.dateTime = new Date(errorLog.dateTime);
        this.dateString = this.dateTime.toLocaleDateString();
        this.timeString = this.dateTime.toLocaleTimeString();

        this.message = errorLog.message;
        this.stacktrace = errorLog.stacktrace;
        this.extraInfo = errorLog.extraInfo;
        this.severity = errorLog.severity;
        this.machineName = errorLog.machineName;
        this.data = errorLog.data;

        this.pageAuditId = errorLog.pageAuditId;

        if (errorLog.pageAuditId) {
            this.pageAudit = new PageAuditModel(errorLog.pageAudit);
        }
    }
}
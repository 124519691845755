import { mapState, mapActions, mapGetters } from 'vuex'

const breadStoreName = 'bread'

export default {
    computed: {
        ...mapState('session', [
            'auth',
            'buildMode', 
            'disableCaptcha',
            'environment',
            'error', 
            'hasError', 
            'isLoading', 
            'isLoadingTheme',
            'isProduction',
            'recaptchaPublicKey', 
            'useVueRouter',
            'vuejsDatepickerLanguage'
        ]),

        ...mapState('resource', [
            'currentLocale'
        ]),

        ...mapGetters('session', ['isAdmin', 'isDevelopment']),
    },

    methods: {
        ...mapActions('session', ['setIsLoading', 'setIsLoadingTheme', 'logError', 'clearError']),

        ...mapActions(breadStoreName, ['setCrumbs']),

        ...mapActions('headerbar', ['refreshHeaderBarLinks']),

        ...mapActions('feature', ['getFeature', 'getResolvedFeature']),

        getMvcLink(controller, action, id, parameters) {
            if (!action) {
                action = 'Index'
            }
            
            let url = window.location.origin + '/' + controller + '/' + action

            if (id) {
                url = url + '/' + id
            }

            if (parameters) {
                url = url + '?' + parameters
            }

            return url
        },

        mvcNavigate(controller, action, id, parameters) {
            window.location.assign(this.getMvcLink(controller, action, id, parameters))
        },

        async confirmDelete(warning) {
            return await this.$bvModal.msgBoxConfirm(warning, { 
                title: this.$i18n.t('Standard.Delete'),
                okVariant: 'warning',
                okTitle: this.$i18n.t('Standard.Yes'),
                cancelTitle: this.$i18n.t('Standard.Cancel'),
                centered: true
            })
        },

        showToast(text, variant) {
            this.$bvToast.toast(text, {
                toaster: 'b-toaster-bottom-left',
                autoHideDelay: 3500,
                variant: variant,
                noCloseButton: true,
                solid: true,
                appendToast: true
            })
        },

        registerStore(storeName, module) {
            const store = this.$store

            if (!(store && store.state && store.state[storeName])) {
                store.registerModule(storeName, module)
            }
        },

        unregisterStore(storeName) {
            const store = this.$store

            if ((store && store.state && store.state[storeName])) {
                store.unregisterModule(storeName)
            }
        },

    	'$eval'(expr) {
            return eval(expr)
        }
    }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              !!_vm.$slots.header
                ? _c(
                    "b-row",
                    { staticClass: "text-center mt-5" },
                    [_c("b-col", [_vm._t("header")], 2)],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !!_vm.$slots.default
                ? _c(
                    "b-row",
                    { staticClass: "text-center mt-4 mb-5" },
                    [_c("b-col", [_vm._t("default")], 2)],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { ClientLiveLandingQuestionOptionModel } from './client-live-landing-question-option-model'

export class ClientLiveLandingQuestionModel {
	id = null

	index = 0

	isEnabled = true

	resources = { }

	questionType = 'SelectOne'

	options = []

	triggeredBy = []

	parent = null

	_isLastQuestion = false

	constructor(entity, parent) {
		this.id = entity.id
		this.index = entity.index
		this.isEnabled = entity.isEnabled

		this.resources = entity.resources

		this.questionType = entity.questionType

		if (entity.options) {
			entity.options.filter(o => o.isEnabled).sort((a, b) => a.index - b.index).forEach(e => {
				this.options.push(new ClientLiveLandingQuestionOptionModel(e, this))
			})
		}

		this.parent = parent
	}

	hasOption(id) {
		return this.options.some(f => f.id == id)
	}

	addTrigger(optionId) {
		if (!(this.triggeredBy.includes(optionId))) {
			this.triggeredBy.push(optionId)
        }
	}

	removeTrigger(optionId) {
		this.triggeredBy = this.triggeredBy.filter(t => t != optionId)
		if (!this.isVisible) {
			this.clearSelections()
		}
	}

	setLastQuestion(value) {
		this._isLastQuestion = value
    }

	clearTriggers() {
		this.triggeredBy = []
	}

	get isLastQuestion() {
		return this._isLastQuestion
    }

	get isVisible() {
		return this.triggeredBy.length
    }

	get selectedOption() {
		const selected = this.options.find(o => o.isSelected)

		return selected ? selected.id : null
	}

	// Comes from the v-model in the landing-question component for the radio button group
	// Value is the option id
	set selectedOption(value) {
		if (this.questionType == 'SelectOne') {
			this.options.forEach(o => o.isSelected = (o.id == value))
		}
	}

	// Called by the refreshQuestionState in client-live-landing-model class.
	get hasSelectedOption() {
		// is there any option selected?
		return this.options.some(o => o.isSelected)
	}

	get selectedOptionIds() {
		return this.options.filter(o => o.isSelected).map(o => o.id)
	}

	clearSelections() {
		this.options.forEach(o => o.clearSelection())
	}

	get activeEndingOption() {
		let options = this.options.filter(o => o.isSelected && o.willEndQuestions)
		return options ? options[0] : null
    }
}
export class ContactModel {
    constructor(contact, owner) {
        if (contact) {
            this.contactId = contact.ContactId.toString()
            this.id = this.contactId
            this.contactName = contact.FullName
            this.isActive = contact.IsActive
            this.isLocked = contact.IsLocked
            this.type = contact.Type
            this.userId = contact.UserId

            this.owner = owner

            this.isClient = (contact.Type === 0)
        }
        else {
            this.contactId = null
            this.id = null
            this.contactName = null
            this.isActive = null
            this.isLocked = null
            this.type = null
            this.userId = null

            this.owner = null

            this.isClient = false
        }

        this.depth = this.owner ? this.owner.depth + 1 : 0

        this.path = this.owner ? this.owner.getFullParentPath() : ''

        const fullParentPath = this.getFullParentPath()
        const currentName = (this.isClient ? this.contactName : this.contactName + ' [' + this.userId + ']')

        this.fullPath = currentName + '  (' + fullParentPath + ')'

        const maxLength = 60

        if (fullParentPath.length > maxLength) {
            let indexOf = fullParentPath.lastIndexOf(' > ', fullParentPath.length - maxLength)

            this.fullPathTruncated = currentName + '  (...' + fullParentPath.substring(indexOf) + ')'
        }
        else {
            this.fullPathTruncated = this.fullPath
        }

        this.selected = false
        this.expanded = !this.owner

        this.children = []

        if (this.isClient) {
            if (contact.Children && contact.Children.length > 0) {
                contact.Children.forEach(f => this.children.push(new ContactModel(f, this)))
            }
        }
    }

    get users() {
        return this.children.filter(c => !c.isClient)
    }

    get clients() {
        return this.children.filter(c => c.isClient)
    }

    contactPath() {
        if (!this.owner) {
            return this.contactName
        }

        if (this.owner && this.owner.owner) {
            var parentPath = this.owner.contactPath()

            return (parentPath ? parentPath + ' > ' : '') + this.owner.contactName
        }

        return ''
    }

    getFullParentPath() {
        return this.owner ? this.owner.getFullParentPath() + (this.owner.owner ? ' > ' : '') + this.contactName : ''
    }

    getContactById(contactId) {
        if (!this)
            return null

        if (this.contactId === contactId) {
            return this
        }

        for (let child of this.children) {
            var contact = child.getContactById(contactId)

            if (contact) {
                return contact
            }
        }

        return null
    }

    flatten(sorted) {
        let list = []

        this.flattenRecursive(list)

        return sorted ? list.sort((a, b) => a.contactName < b.contactName ? -1 : 1) : list
    }

    flattenRecursive(list) {
        list.push(this)

        for (let child of this.children) {
            child.flattenRecursive(list)
        }
    }

    expandBySearchString(searchString) {
        if (!this)
            return

        if (this.contactName.search(`/${searchString}/i`) != -1) {
            this.expanded = true
        }

        for (let child of this.children) {
            child.expandBySearchString(searchString)
        }
    }

    expandSelfAndParents() {
        this.expanded = true

        if (!this.owner)
            return

        this.owner.expandSelfAndParents()
    }

    getSelected(selectedList) {
        if (this.selected) {
            selectedList.push(this)
        }

        this.children.forEach(f => {
            f.getSelected(selectedList)
        })
    }

    resetSelected() {
        this.selected = false

        this.children.forEach(f => {
            f.resetSelected()
        })
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-bar" },
    [
      _vm.theme.informationBarRaw
        ? _c("div", [
            _c("div", {
              attrs: { id: "nav-informationbar" },
              domProps: { innerHTML: _vm._s(_vm.theme.informationBarRaw) }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-navbar",
        {
          class: { "box-shadow": !_vm.showShadowBar },
          attrs: { toggleable: "lg", type: "dark", variant: "primary" }
        },
        [
          _c("b-navbar-brand", [_c("header-logo")], 1),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c("header-navbar-main"),
              _vm._v(" "),
              !_vm.theme.disableLanguageToggle
                ? _c("header-navbar-language")
                : _vm._e(),
              _vm._v(" "),
              _c("header-navbar-user")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.eivId
        ? _c("eiv-session-timeout", { attrs: { eivId: _vm.eivId } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showShadowBar
        ? _c("div", {
            class: {
              "shadowing-bar": true,
              shadowing: _vm.auth.isShadowing,
              "box-shadow": true
            },
            attrs: { id: "shadowbar" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { ClientAttributesModel } from '@/models/client/client-attributes-model'

import { cloneDeep, debounce } from 'lodash'

import { ApiFactory } from '@/api/api-factory'

const accountApi = ApiFactory.get('account')

export const ClientStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        client: null,
        clientLoaded: false,
        hasChanges: false
    },

    actions: {
        async getClient ({ commit, state }, id) {
            let response = await accountApi.getContact(id)

            if (Object.keys(response.data).length !== 0) {
                let client = response.data

                // Check if there an existing landing page configuration
                client.attributes = client.attributes ? 
                    new ClientAttributesModel(JSON.parse(client.attributes)) : 
                    new ClientAttributesModel({ contactId: client.contactId })

                commit('SET_CLIENT', client)
            }
            else {
                commit('SET_CLIENT', null)
            }

            commit('SET_HAS_CHANGES', false)

            commit('SET_CLIENT_LOADED', true)

            return state.client
        },

        updateClient({ commit }, client) {
            commit('SET_CLIENT', client)

            commit('SET_HAS_CHANGES', true)
        },

        async saveClient({ commit }, client) {
            const updatedClient = cloneDeep(client)

            let response = await accountApi.updateContact(updatedClient.contactId, updatedClient.attributes)

            let attributes = response.data

            updatedClient.attributes = new ClientAttributesModel(attributes)

            commit('SET_CLIENT', updatedClient)

            commit('SET_HAS_CHANGES', false)
        }
    },

    mutations: {
        SET_CLIENT (state, client) {
            state.client = cloneDeep(client)
        },

        SET_HAS_CHANGES (state, hasChanges) {
            state.hasChanges = hasChanges
        },

        SET_CLIENT_LOADED(state, clientLoaded) {
            state.clientLoaded = clientLoaded
        }
    }
}
import { EmailedRequestModel } from '@/models/emailed-requests/emailed-request-model'

import { ApiFactory } from '@/api/api-factory'

const eivApi = ApiFactory.get('eiv')

export const EmailedRequestsStore = {
    strict: process.env.NODE_ENV !== 'production',

    namespaced: true,

    state: {
        requestsLoaded: false,
        requests: null,
        stagingId: null,
        nextProcessingTime: null,
        nextProcessingTimeLoaded: false
    },

    actions: {
        // api requests
        async getEmailedRequests({ commit }) {
            let response = await eivApi.getEmailedRequests()

            if (response.data.emailRequests && (Array.isArray(response.data.emailRequests) || Object.keys(response.data.emailRequests).length !== 0)) {
                commit('SET_REQUESTS', response.data.emailRequests)
            } else {
                commit('SET_REQUESTS', null)
            }

            commit('SET_REQUESTS_LOADED', true)
        },

        async createTransactionFromEmailedRequest({}, request) {
            let emailRequestId = Number(request.id) // Make it an int
            let response = await eivApi.createTransactionFromEmailedRequest(emailRequestId)
        },

        async deleteEmailedRequest({ commit, dispatch }, request) {
            let emailRequestId = Number(request.id) // Make it an int
            let response = await eivApi.deleteEmailedRequest(emailRequestId)

            if (response.data === true) {
                dispatch('getEmailedRequests') // Fetch new list
            } else {
                console.log(`response was ${response}`)
            }
        },

        async GetNextProcessingTime({ commit }) {
            let response = await eivApi.GetNextProcessingTime()
            if (response.data) {
                commit('SET_NEXT_PROCESSING_TIME', response.data)
            } else {
                commit('SET_NEXT_PROCESSING_TIME', null)
            }

            commit('SET_NEXT_PROCESSING_TIME_LOADED', true)
        }
    },

    mutations: {
        // Commit results of api request (from the action) to state
        SET_REQUESTS(state, payload) {
            let newRequests = []

            if (payload) {
                payload.forEach(r => newRequests.push(new EmailedRequestModel(r)))
            }

            state.requests = newRequests
        },

        SET_REQUESTS_LOADED(state, payload) {
            state.requestsLoaded = payload
        },

        // Commit results of api request (from the action) to state
        SET_NEXT_PROCESSING_TIME(state, payload) {
            state.nextProcessingTime = payload
        },

        SET_NEXT_PROCESSING_TIME_LOADED(state, payload) {
            state.nextProcessingTimeLoaded = payload
        }
    }
}
<template>
    <b-img v-if="!isLoadingTheme" id="nav-logo" ref="navLogo" :src="`/Content/${currentLocale ? currentLocale : 'en'}/images/WhiteLabel/${theme.logos.logo}`"></b-img>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        name: 'header-logo',

        computed: {
            ...mapState('session', ['theme'])
        }
    }

</script>
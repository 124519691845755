import Api from '../api'

export default {
    getProcessorEmailTemplates() {
        return Api.get('/InternalToolsApi/GetProcessorEmailTemplates')
    },
    editProcessorEmailTemplate(model) {
        return Api.post(`/InternalToolsApi/EditProcessorEmailTemplate`, JSON.stringify(model))
    },
    deleteProcessorEmailTemplate(id) {
        return Api.delete('/InternalToolsApi/DeleteProcessorEmailTemplate?id=' + id)
    }
}
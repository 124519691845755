<template>
    <b-navbar-nav class="nav-language ml-2">
        <b-nav-item-dropdown id="nav-item-language" :text="$t('HeaderBar.Language')" right>
            <b-dropdown-item v-for="locale in locales" :key="locale.id" :id="'nav-item-' + locale.id" @click="setLocale(locale.id)">{{ locale.resource }}</b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    import { BNavbarNav, BNavItemDropdown } from 'bootstrap-vue'

    export default {
        name: 'header-navbar-language',

        components: {
            BNavbarNav,
            BNavItemDropdown
        },

        computed: {
            ...mapState('resource', ['locales'])
        },

        methods: {
            ...mapActions('resource', ['setLocale'])
        }
    }
</script>
export class ClientLiveLandingGroupPackageFeeModel {
	base = 0.0
	taxRate = 0.0
	taxes = 0.0
	total = 0.0
	currency = null

	constructor(entity) {
		this.base = entity.base
		this.taxRate = entity.taxRate
		this.taxes = entity.taxes
		this.total = entity.total
		this.currency = entity.currency
	}
}
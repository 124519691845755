var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "nav-main ml-auto" },
    _vm._l(_vm.headerBarLinks, function(link) {
      return _vm.headerBarLinks && _vm.isNavItemVisible(link.isVisible)
        ? _c(
            "b-nav-item",
            {
              class: _vm.classObject(link),
              attrs: {
                id: "header-bar-link-" + link.id,
                href: link.href,
                to: _vm.useVueRouter ? link.to : "/v" + link.to,
                append: ""
              }
            },
            [
              link.warning
                ? _c("v-icon", {
                    staticClass: "text-danger",
                    attrs: { icon: "exclamation-triangle" }
                  })
                : _vm._e(),
              _vm._v(
                "\n        " +
                  _vm._s(
                    link.resource
                      ? _vm.$t(link.resource)
                      : _vm.$t("HeaderBar." + link.id)
                  ) +
                  "\n        "
              ),
              link.badge && _vm.badgeValue(link.badge) > 0
                ? _c("b-badge", { attrs: { variant: "light" } }, [
                    _vm._v(_vm._s(_vm.badgeValue(link.badge)))
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createMutationsSharer from 'vuex-shared-mutations'

import { initializeStores, modules } from "./store-accessor";

Vue.use(Vuex)

//const initializer = (store) => initializeStores(store);
//export const plugins = [initializer];
//export * from "./store-accessor";

const vuexLocal = new VuexPersistence({
    strictMode: process.env.NODE_ENV !== 'production',
    storage: window.localStorage,
    reducer: (state) => ({ session: { hideBackground: state.session.hideBackground, autoResetTimeoutNextTick: state.session.autoResetTimeoutNextTick }}),
    filter: (mutation) => mutation.type === 'session/SET_HIDE_BACKGROUND' || mutation.type === 'session/SET_AUTO_RESET_TIMEOUT_NEXT_TICK'
})

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    mutations: {
        RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION
    },

    plugins: [
        //initializer,
        createMutationsSharer({ predicate: ['session/RESET_TIMEOUT', 'session/SET_HIDE_BACKGROUND', 'session/SET_AUTO_RESET_TIMEOUT_NEXT_TICK'] }),
        vuexLocal.plugin
    ],

    modules
})

store.dispatch('session/initSession')

export default store
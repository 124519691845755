import ViewFactory from '@/router/view-factory'

export class RouteModel {
	path = null
	component = null
	name = null
	componentPath = null
	redirect = null
	props = null
	alias = null

	children = null

	meta = null

	constructor(entity) {
		this.path = entity.path
		this.componentPath = entity.componentPath
		this.name = entity.name
		this.redirect = entity.redirect
		this.props = entity.props
		this.alias = entity.alias

		if (this.componentPath) {
			this.component = () => { return ViewFactory.get(this.componentPath) }
		}

		if (entity.children) {
			this.children = []

			entity.children.forEach(e => this.children.push(new RouteModel(e)))
		}

		this.meta = entity.meta
	}
}
import Api from '../api'

export default {
    getClientInvoices(start, end) {
        return Api.get('/Invoice/GetClientInvoices', 
            {
                params: {
                    start: start,
                    end: end
                }
            })
    },

    getClientInvoicesFile(start, end, clientIds) {
        return Api.post('/Invoice/GetClientInvoicesFile', 
            {
                start: start,
                end: end,
                clientIds: clientIds
            },
            {
                responseType: 'blob'
            })
    }
}
import { ClientLiveLandingModel } from './client-live-landing-model'
import { WhiteLabelModel } from '../white-label/white-label-model'
import { LandingLookupPositionModel } from './landing-lookup-position-model'

import store from '@/stores/store'

export class LandingModel {
	contactLanding = null

	whiteLabel = null

	positions = []

	constructor(entity) {
		this.contactLanding = new ClientLiveLandingModel(entity)

		this.whiteLabel = new WhiteLabelModel(entity.whiteLabel)

		const positions = store.state.feature.features['Landing'].meta.positions

		if (positions && this.contactLanding.groups) {
			let usedGroups = []

			this.contactLanding.groups.forEach(g => {
				const group = positions.groups.find(pg => pg.name == g.positions)

				usedGroups.push(group)
			})

			Object.keys(positions.types).forEach(key => {
				const position = positions.types[key]

				const groups = usedGroups.map(g => g.positions.some(p => p.name == position.name))

				this.positions.push(new LandingLookupPositionModel(position, groups))
			})
		}
	}
}
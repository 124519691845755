import Api from '../api'

export default {
    getLinks() {
        return Api.get('/LinkApi/GetLinks')
    },

    addLink(link) {
        return Api.post('/LinkApi/AddLink', JSON.stringify(link))
    },

    updateLink(link) {
        return Api.put('/LinkApi/UpdateLink', JSON.stringify(link))
    },

    deleteLink(link) {
        return Api.delete('/LinkApi/DeleteLink?id=' + link.id)
    }
}
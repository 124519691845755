export class WhiteLabelModel {
	companyId = null

	logos = null

	colors = null

	informationBarRaw = null

	disableLanguageToggle = false

	constructor(entity) {
		this.companyId = entity.companyId
		this.logos = entity.logos
		this.colors = entity.colors
		this.informationBarRaw = entity.informationBarRaw
		this.disableLanguageToggle = entity.disableLanguageToggle
	}
}
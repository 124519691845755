export class LandingLookupPositionModel {
	name = null

	resources = null

	groups = []

	constructor(entity, groups) {
		this.name = entity.name

		this.resources = entity.resources

		this.groups = groups
	}
}
import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export class ClientLandingQuestionOptionModel {
	id = null

	index = 0

	isEnabled = true

	willEndQuestions = false

	willEndQuestionsMessage = {}

	nextAction = null

	forceSingleSelection = false

	resources = {}

	constructor(args) {
		// Entity exists when there are existing questions and question options in the database.
		const entity = args ? args.entity : null

		if (entity) {
			this.id = entity.id
			this.index = entity.index
			this.isEnabled = entity.isEnabled

			this.nextAction = entity.nextAction

			this.willEndQuestions = entity.willEndQuestions

			// If an option already exists but doesn't have the willEndQuestionsMessage property, it needs to be added.
			// This will grab the default text from the Client feature and put that as willEndQuestionsMessage.
			if (!entity.willEndQuestionsMessage) {
				this.willEndQuestionsMessage = store.state.feature.features['Client'].meta.defaults.landing.questions.options.willEndQuestionsMessage
			} else {
				this.willEndQuestionsMessage = entity.willEndQuestionsMessage
            }

			this.forceSingleSelection = entity.forceSingleSelection

			this.resources = entity.resources
		}
		else {
			this.id = uuidv4()

			// No existing entity - this means it's a new option.
			// Args contain the default text from Client feature in SQL.
			if (args) {
				this.resources = args.resources
				this.willEndQuestionsMessage = args.willEndQuestionsMessage
			}
		}

		// Add missing locales
		const locales = store.state.feature.features['App'].meta.locales

		if (locales) {
			locales.forEach(locale => {
				if (!this.resources[locale.id]) {
					this.resources[locale.id] = { 'Name': '', 'Description': '' }
				}

				if (!this.resources[locale.id].Description) {
					this.resources[locale.id].Description = ''
				}

				if (!this.willEndQuestionsMessage[locale.id]) {
					this.willEndQuestionsMessage[locale.id] = { 'Message': '' }
				}
			})
		}
	}
}
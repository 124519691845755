import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'fr': {
    currency: {
      style: 'currency', currency: 'CAD'
    }
  }
}

const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        shortWithTime: {
            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
        }
  },
  'fr': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'long', day: 'numeric'
    }
  }
}

const international = new VueI18n({
    locale: window.locale,
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    numberFormats,
    dateTimeFormats,
    messages: {
        en: {}
    }
})

export default international
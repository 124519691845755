import { ApiFactory } from '@/api/api-factory'

const reportsApi = ApiFactory.get('reports')

export const ReportsStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        listLoaded: false,
        fileList: [],
        downloadError: null
    },

    actions: {
        async downloadReportFile({ commit }, params) {

            let response = await reportsApi.downloadReportFile(params.fileName, params.clientId);

            if (response.data) {
                console.log('successful download call', response.data)
                return response.data
            }
            else {
                commit('SET_ERROR', 'There was an error downloading the requested file')
            }

            return response.data
        },

        async getAllReportFiles({ commit }, params) {

            console.log('action to fetch reports', params)

            let response = await reportsApi.getAllReportFiles(params.clientId, params.startIndex, params.endIndex);

            if (response.data) {
                // Javascript can't access file creation date of a file from another source/server
                commit('SET_FILE_LIST', response.data)
            }
            else {
                console.log('no files received', response.data)
                commit('SET_FILE_LIST_LOADED', null)
            }

            return response.data // returning for the load more button hide/show
        }
    },

    mutations: {

        SET_FILE_LIST(state, payload) {
            state.listLoaded = true;
            console.log(payload, 'payload array console log')
            state.fileList = payload;
        },

        SET_ERROR(state, error) {
            state.downloadError = error
        }
    }
}
import Api from '../api'

export default {
    downloadReportFile(fileName, clientId) {
        return Api.get(`/ReportsApi/DownloadReportFile?fileName=${fileName}&clientId=${clientId}`)
    },
    getAllReportFiles(clientId, startIndex, endIndex) {
        console.log(clientId, startIndex, endIndex, 'did it hit API?')
        return Api.get(`/ReportsApi/GetAllReportFiles?clientId=${clientId}&startIndex=${startIndex}&endIndex=${endIndex}`)
    }
}
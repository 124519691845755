var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _vm.icon
        ? _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-icon", {
                staticClass: "text-black-50",
                staticStyle: {
                  "margin-top": "0.25em",
                  width: "40px",
                  height: "40px"
                },
                attrs: { icon: _vm.icon }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.title && !_vm.subtitle
        ? _c("b-col", { attrs: { cols: "auto", lg: "5" } }, [
            _vm.title
              ? _c(
                  "h4",
                  {
                    staticStyle: {
                      "line-height": "1.5em",
                      "margin-bottom": "0 !important"
                    }
                  },
                  [_vm._v(_vm._s(_vm.title))]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.title && _vm.subtitle
        ? _c("b-col", { attrs: { cols: "auto", lg: "5" } }, [
            _c(
              "h4",
              {
                staticStyle: {
                  "line-height": "1.2em",
                  "margin-bottom": "0 !important"
                }
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _vm._v(" "),
            _c(
              "h6",
              {
                staticStyle: {
                  "line-height": "1.2em",
                  "margin-bottom": "0 !important"
                }
              },
              [_vm._v(_vm._s(_vm.subtitle))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-col"),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { cols: "auto" } },
        [!!_vm.$slots.default ? _vm._t("default") : _vm._e()],
        2
      ),
      _vm._v(" "),
      !!_vm.$slots.menu
        ? _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "auto" } },
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    id: "header-commands",
                    variant: "none",
                    right: "",
                    "toggle-class": "btn-icon",
                    "no-caret": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("v-icon", {
                              staticStyle: { "margin-top": "0.3em" },
                              attrs: { icon: "ellipsis-v" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1158440059
                  )
                },
                [
                  _vm._v(" "),
                  _c("div", { staticClass: "width-300" }, [_vm._t("menu")], 2)
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import cssVars from 'css-vars-ponyfill'

import { ApiFactory } from '@/api/api-factory'

const sessionApi = ApiFactory.get('session')

import * as datetimeHelpers from '@/utilities/datetime'

import Color from 'color'

export const SessionStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        machineName: window.initSession.machineName,
        ipAddress: window.initSession.ipAddress,

        isProduction: window.initSession.isProduction,
        environment: window.initSession.environment,

        sessionTimeout: window.initSession.sessionTimeout,

        disableCaptcha: window.initSession.disableCaptcha,
        recaptchaPublicKey: window.initSession.recaptchaPublicKey,

        auth: window.initSession.auth,

        isLoadingTheme: true,
        theme: window.initSession.theme,

        useVueRouter: window.useVueRouter,

        menu: window.initSession.menu,
        hideBackground: false,

        isLoading: false,

        sessionWarningPeriodLength: 120,
        sessionTimeToEnd: null,
        sessionRemainingTime: 0,
        sessionIntervalId: null,

        autoResetTimeoutNextTick: false,
        resetTimeoutNextTick: false,

        isSiteOffline: false,
        buildMode: process.env.NODE_ENV,

        hasError: false,
        error: null,

        pageAudit: null
    },

    actions: {
        async initSession({ dispatch }) 
        {
            dispatch('refreshTheme')
        },

        async sessionStartTimer ({ commit, dispatch, state }) {
            if (!state.auth.isAuthenticated) {
                return
            }

            await sessionApi.checkSession()

            commit('RESET_TIMEOUT')
            commit('UPDATE_TIMEOUT')

            if (state.sessionIntervalId) {
                commit('STOP_SESSION_TIMER')
            }

            const sessionIntervalId = setInterval(() => {
                if (state.resetTimeoutNextTick || state.autoResetTimeoutNextTick) {
                    commit('RESET_TIMEOUT_NEXT_TICK', false)
                    dispatch('sessionStartTimer')
                } else {
                    commit('UPDATE_TIMEOUT')
                }
            }, 5000)

            commit('SET_SESSION_TIMER_ID', sessionIntervalId)
        },

        login() {
            sessionApi.login()
        },

        logoff() {
            sessionApi.logoff()
        },

        shadow ({ commit, state }, contactId) {
            sessionApi.shadow(contactId)
        },

        unshadow() {
            sessionApi.unshadow()
        },

        setIsLoadingTheme({ commit }, loading) {
            commit('IS_LOADING_THEME', loading)
        },

        async setWhiteLabelById({ commit, dispatch }, params) {
            commit('IS_LOADING_THEME', true)

            let response = await sessionApi.setWhiteLabel(params.whiteLabelId, params.customerLandingId)

            var theme = response.data

            commit('SET_THEME', theme)

            dispatch('refreshTheme')

            commit('IS_LOADING_THEME', false)
        },

        async setWhiteLabel({ commit, dispatch }, theme) {
            commit('SET_THEME', theme)

            dispatch('refreshTheme')

            commit('IS_LOADING_THEME', false)
        },

        refreshTheme({ state }) {
            Object.keys(state.theme.colors).forEach(key => {
                const themeColor = new Color(state.theme.colors[key])

                const cssColorVar = `--white-label-${key}`
                const cssTransparentColorVar = `--white-label-${key}-transparent`
                const cssHoverColorVar = `--white-label-${key}-hover`
                const cssContrastColorVar = `--white-label-${key}-contrast`

                const cssColor = themeColor.hsl().toString()
                const cssTransparentColor = themeColor.alpha(0.3).hsl().toString()
                const cssHoverColor = themeColor.isLight() ? themeColor.darken(0.15).hsl().toString() : themeColor.lighten(0.15).hsl().toString()
                const cssContrastColor = themeColor.isLight() ? '#222' : '#eee'

                document.documentElement.style.setProperty(cssColorVar, cssColor)
                document.documentElement.style.setProperty(cssTransparentColorVar, cssTransparentColor)
                document.documentElement.style.setProperty(cssHoverColorVar, cssHoverColor)
                document.documentElement.style.setProperty(cssContrastColorVar, cssContrastColor)

                if (window.isIE) {
                    const variables = { }

                    variables[cssColorVar] = cssColor
                    variables[cssTransparentColorVar] = cssTransparentColor
                    variables[cssHoverColorVar] = cssHoverColor
                    variables[cssContrastColorVar] = cssContrastColor

                    cssVars({
                        rootElement: document,
                        variables
                    })
                }
            })
        },

        setIsLoading({ commit, state }, isLoading) {
            commit('SET_IS_LOADING', isLoading)
        },

        async getIsSiteOffline({ commit, state }) {
            let response = await sessionApi.getIsSiteOffline()

            let isOffline = response.data

            if (isOffline != state.isSiteOffline) {
                commit('SET_IS_OFFILINE', isOffline)
            }

            if (isOffline === true) {
                sessionApi.goOffline()
            }
        },

        async logError({ commit, state }, error) {
            commit('SET_ERROR', error)

            const response = await sessionApi.log(error);

            return response.data;
        },

        clearError({ commit, state }) {
            commit('CLEAR_ERROR')
        },

        async startPageAudit({ commit, state }, pageAudit) {
            const response = await sessionApi.startPageAudit(pageAudit)

            pageAudit.id = response.data

            commit('SET_PAGE_AUDIT', pageAudit)
        },

        async completePageAudit({ commit, state }) {
            await sessionApi.completePageAudit(state.pageAudit)
        },

        userActive({ commit, state }) {
            commit('RESET_TIMEOUT_NEXT_TICK', true)
        },

        setAutoResetTimeoutNextTick({ commit, state }, auto) {
            commit('SET_AUTO_RESET_TIMEOUT_NEXT_TICK', auto)
        },

        setHideBackground({ commit, state }, hide) {
            commit('SET_HIDE_BACKGROUND', hide)
        },
    },

    getters: {
        isAuthenticated(state) {
            return state.auth.isAuthenticated
        },

        isAdmin(state) {
            return state.auth.roles.includes('Admin');
        },

        canShadow(state) {
            return state.auth.roles.includes('Shadow');
        },

        isInRoles: (state, getters) => roles => {
            let intersection = state.auth.roles.filter(x => roles.includes(x))

            return getters.isAuthenticated && intersection.length > 0
        },

        hasPermission: (state, getters) => permissions => {
            let intersection = state.auth.permissions.filter(x => permissions.includes(x.name))

            return getters.isAuthenticated && intersection.length > 0
        },

        getShowRedirect(state) {
            return state.sessionRemainingTime <= 0
        },

        getShowWarning(state) {
            return state.sessionRemainingTime <= state.sessionWarningPeriodLength
        },

        isDevelopment(state) {
            return !state.isProduction && window.initSession.environment === 'local'
        },

        userIdConsideringShadowing(state) {
            return state.auth.isShadowing ? state.auth.shadowId : state.auth.identityId
        },

        canEditUserDetails(state, getters) {
            return getters.hasPermission(['CanEditUserDetails', 'CanEditClientDetails'])
        },

        canEditClientDetails(state, getters) {
            return getters.hasPermission(['CanEditClientDetails'])
        }
    },

    mutations: {
        SET_MACHINE_NAME (state, machineName) {
            state.machineName = machineName
        },

        SET_IP_ADDRESS (state, ipAddress) {
            state.ipAddress = ipAddress
        },

        SET_VERSION (state, version) {
            state.version = version
        },

        SET_SESSION_TIMEOUT (state, timeout) {
            state.sessionTimeout = timeout
        },

        SET_AUTH (state, auth) {
            state.auth = auth
        },

        SET_THEME (state, theme) {
            state.theme = theme
        },

        SET_MENU (state, menu) {
            state.menu = menu
        },

        SET_AUTO_RESET_TIMEOUT_NEXT_TICK(state, val) {
            state.autoResetTimeoutNextTick = val
        },

        RESET_TIMEOUT_NEXT_TICK(state, val) {
            state.resetTimeoutNextTick = val
        },

        RESET_TIMEOUT (state) {
            state.sessionTimeToEnd = datetimeHelpers.addMinutes(new Date(), state.sessionTimeout)
        },

        UPDATE_TIMEOUT (state) {
            state.sessionRemainingTime = datetimeHelpers.dateDiffSeconds(state.sessionTimeToEnd)
        },

        SET_IS_LOADING (state, isLoading) {
            state.isLoading = isLoading
        },

        CLEAR_ERROR (state) {
            state.hasError = false
            state.error = null
        },

        SET_ERROR(state, error) {
            state.hasError = true
            state.error = error
        },

        SET_IS_OFFILINE (state, isOffline) {
            state.isSiteOffline = isOffline
        },

        IS_LOADING_THEME(state, isLoadingTheme) {
            state.isLoadingTheme = isLoadingTheme
        },

        STOP_SESSION_TIMER(state) {
            clearInterval(state.sessionIntervalId)

            state.sessionIntervalId = null
        },

        SET_SESSION_TIMER_ID(state, sessionIntervalId) {
            state.sessionIntervalId = sessionIntervalId
        },

        SET_HIDE_BACKGROUND(state, hide) {
            state.hideBackground = hide
        },

        SET_PAGE_AUDIT(state, pageAudit) {
            state.pageAudit = pageAudit
        }
    }
}
import { ClientLiveLandingGroupPackageFeeModel } from './client-live-landing-group-package-fee-model'

export class ClientLiveLandingGroupPackageModel {
	id = null

	packageId = null

	clientPackageId = null

	index = 0

	isEnabled = true

	enableJurisdiction = false

	resources = { }

	code = null

	startUrl = null

	fee = null

	questionOptions = []

	isRecommended = false

	parent = null

	constructor(entity, parent) {
		this.id = entity.id
		this.packageId = entity.packageId
		this.clientPackageId = entity.clientPackageId
		this.index = entity.index
        this.isEnabled = entity.isEnabled

		this.enableJurisdiction = entity.enableJurisdiction

		this.resources = entity.resources

		this.code = entity.code

		this.startUrl = entity.startUrl

		if (entity.fee) {
			this.fee = new ClientLiveLandingGroupPackageFeeModel(entity.fee)
		}

		if (entity.questionOptions) {
			this.questionOptions = entity.questionOptions
		}

		this.parent = parent
	}
}
import { ContactModel } from '@/models/contact/contact-model'

import { ApiFactory } from '@/api/api-factory'

const accountApi = ApiFactory.get('account')

export const ContactStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        contactRoot: null,
        contactFlatList: null,
        contactsLoaded: false,
        showInactiveUsers: true,
        expandedContact: null,
        selectedContact: null,
        areInactiveUsersHidden: false,
        allowMultiSelect: true,
        closeSelection: false,
        clientRoot: null,
        clientFlatList: null,
        clientsLoaded: false,
        userRoot: null,
        userFlatList: null,
        usersLoaded: false, 
        packagesAvailable: null
    },

    actions: {
        async getSimpleContacts ({ commit, state }, reload = true) {
            if (!reload && state.contactsLoaded) {
                return
            }

            let response = await accountApi.getSimpleContacts()

            if (Object.keys(response.data).length !== 0) {
                commit('SET_CONTACT_ROOT', response.data)
            }
            else {
                commit('SET_CONTACT_ROOT', null)
            }

            commit('SET_CONTACTS_LOADED', true)
        },
        async getClients({ commit, state }) {
            let response = await accountApi.getClients()

            if (Object.keys(response.data).length !== 0) {
                commit('SET_CLIENT_ROOT', response.data)
            }
            else {
                commit('SET_CLIENT_ROOT', null)
            }
            commit('SET_CLIENTS_LOADED', true)
        },
        async getUsers({ commit, state }, clientId) {
            let response = await accountApi.getUsers(clientId)

            if (Object.keys(response.data).length !== 0) {
                commit('SET_USER_ROOT', response.data)
            }
            else {
                commit('SET_USER_ROOT', null)
            }
            commit('SET_USERS_LOADED', true)

            return state.userFlatList
        },
        async getClientPackages({ commit, state }, clientId) {
            let response = await accountApi.getClientPackages(clientId)
            commit('SET_CLIENT_PACKAGES', response.data)
            return state.packagesAvailable
        },
        async activate ({ commit, state }, contactId) {
            let response = await accountApi.activate(contactId)

            commit('SET_CONTACT_ACTIVE', contactId)
        },

        async deactivate ({ commit, state }, contactId) {
            let response = await accountApi.deactivate(contactId)

            commit('SET_CONTACT_INACTIVE', contactId)
        },

        async unlock ({ commit, state }, contactId) {
            let response = await accountApi.unlock(contactId)

            commit('SET_CONTACT_UNLOCKED', contactId)
        },

        async resetPassword ({ commit, state }, contactId) {
            await accountApi.resetPassword(contactId)
        },

        addUser ({ commit, state }, contactId) {
            accountApi.addUser(contactId)
        },

        addClient ({ commit, state }, contactId) {
            accountApi.addClient(contactId)
        },

        copyUser ({ commit, state }, contactId) {
            accountApi.copyUser(contactId)
        },

        copyClient ({ commit, state }, contactId) {
            accountApi.copyClient(contactId)
        },

        editUser ({ commit, state }, contactId) {
            accountApi.editUser(contactId)
        },

        editClient ({ commit, state }, contactId) {
            accountApi.editClient(contactId)
        },

        expandBySearchString({ commit, state }, searchString) {
            commit('EXPAND_BY_SEARCHSTRING', searchString)
        },

        toggleExpandByContactId({ commit, state }, contactId) {
            commit('TOGGLE_EXPAND_BY_CONTACT_ID', contactId)
        },

        expandByContactId({ commit, state }, contactId) {
            commit('EXPAND_BY_CONTACT_ID', contactId)
        },

        selectByContactId({ commit }, contactId) {
            commit('SELECT_BY_CONTACT_ID', contactId)
        },

        deselectByContactId({ commit }, contactId) {
            commit('DESELECT_BY_CONTACT_ID', contactId)
        },

        deselectAll({ commit }) {
            commit('DESELECT_ALL')
        },

        toggleSelectedByContactId({ commit }, contactId) {
            commit('TOGGLE_SELECTED_BY_CONTACT_ID', contactId)
        },

        setAllowMultiSelect({ commit }, allow) {
            commit('SET_ALLOW_MULTI_SELECT', allow)
        },

        setHideInactiveUsers({ commit }, hide) {
            commit('SET_INACTIVE_USERS_HIDDEN', hide)
        },

        selectAndCloseByContactId({ commit }, contactId) {
            commit('SELECT_AND_CLOSE_BY_CONTACT_ID', contactId)
        }
    },

    getters: {
        selectedContacts(state) {
            return state.contactFlatList && state.contactFlatList.filter(c => c.selected)
        },

        hasSelectedContacts(state) {
            return state.contactFlatList && state.contactFlatList.some(c => c.selected)
        }
    },

    mutations: {
        SET_CONTACT_ROOT (state, contactRoot) {
            state.contactRoot = new ContactModel(contactRoot)

            state.contactFlatList = state.contactRoot.flatten(true)
        },

        SET_CONTACTS_LOADED(state, contactsLoaded) {
            state.contactsLoaded = contactsLoaded
        },

        SET_CONTACT_ACTIVE(state, contactId) {
            let contact = state.contactRoot.getContactById(contactId)

            contact.isActive = true
        },

        SET_CONTACT_INACTIVE(state, contactId) {
            let contact = state.contactRoot.getContactById(contactId)

            contact.isActive = false
        },

        SET_CONTACT_UNLOCKED(state, contactId) {
            let contact = state.contactRoot.getContactById(contactId)

            contact.isLocked = false
        },

        SET_CONTACTS_LOADED(state, contactsLoaded) {
            state.contactsLoaded = contactsLoaded
        },

        SET_CLIENT_ROOT(state, clientRoot) {
            state.clientRoot = new ContactModel(clientRoot)

            state.clientFlatList = state.clientRoot.flatten(true)
        },

        SET_CLIENTS_LOADED(state, clientsLoaded) {
            state.clientsLoaded = clientsLoaded
        },

        SET_CLIENT_PACKAGES(state, packages) {
            state.packagesAvailable = packages
        },

        SET_USER_ROOT(state, userRoot) {
            state.userRoot = new ContactModel(userRoot)
            var allUsers = state.userRoot.flatten(true)
            allUsers = allUsers.filter(function (obj) {
                return obj.isClient == false;
            })
            state.userFlatList = allUsers
        },

        SET_USERS_LOADED(state, usersLoaded) {
            state.usersLoaded = usersLoaded
        },

        EXPAND_BY_SEARCHSTRING(state, searchString) {
            state.contactRoot.expandBySearchString(searchString)
        },

        TOGGLE_EXPAND_BY_CONTACT_ID(state, contactId) {
            const contact = state.contactRoot.getContactById(contactId)

            contact.expanded = !contact.expanded
        },

        EXPAND_BY_CONTACT_ID(state, contactId) {
            state.contactFlatList.forEach(c => c.expanded = false)

            state.expandedContact = state.contactRoot.getContactById(contactId)

            state.expandedContact.expandSelfAndParents()
        },

        SELECT_BY_CONTACT_ID(state, contactId) {
            if (!state.allowMultiSelect) {
                state.contactFlatList.forEach(c => c.selected = false)
            }

            state.selectedContact = state.contactRoot.getContactById(contactId)

            state.selectedContact.selected = true
        },

        DESELECT_BY_CONTACT_ID(state, contactId) {
            state.selectedContact = state.contactRoot.getContactById(contactId)

            state.selectedContact.selected = false
        },

        DESELECT_ALL(state) {
            state.contactFlatList.forEach(c => c.selected = false)
        },

        TOGGLE_SELECTED_BY_CONTACT_ID(state, contactId) {
            state.selectedContact = state.contactRoot.getContactById(contactId)

            if (!state.allowMultiSelect && !state.selectedContact.selected) {
                state.contactFlatList.forEach(c => c.selected = false)
            }

            state.selectedContact.selected = !state.selectedContact.selected
        },

        SELECT_AND_CLOSE_BY_CONTACT_ID(state, contactId) {
            state.selectedContact = state.contactRoot.getContactById(contactId)

            if (!state.allowMultiSelect) {
                state.contactFlatList.forEach(c => c.selected = false)
            }

            state.selectedContact.selected = true

            state.closeSelection = true
        },

        SET_ALLOW_MULTI_SELECT(state, allow) {
            state.allowMultiSelect = allow
        },

        SET_INACTIVE_USERS_HIDDEN(state, hide) {
            state.areInactiveUsersHidden = hide
        }
    }
}
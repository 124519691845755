import Api from '../api'

export default {
    getMaxFileSize() {
        return Api.get('/ContentApi/GetMaxFileSize')
    },

    getContentCategories() {
        return Api.get('/ContentApi/GetContentCategories')
    },

    reduceOwnerReferences(key, value) {
        if (key == 'owner') {
            return null;
        }
        else {
            return value;
        }
    },

    addCategory(category) {
        return Api.post('/ContentApi/AddFileCategory', JSON.stringify(category, this.reduceOwnerReferences))
    },

    updateCategory(category) {
        return Api.put('/ContentApi/UpdateFileCategory?fileCategoryId=' + category.fileCategoryId, JSON.stringify(category, this.reduceOwnerReferences))
    },

    deleteCategory(category) {
        return Api.delete('/ContentApi/DeleteFileCategory?fileCategoryId=' + category.fileCategoryId)
    },

    addFile(file) {
        return Api.post('/ContentApi/AddFile', JSON.stringify(file, this.reduceOwnerReferences))
    },

    updateFile(file) {
        return Api.put('/ContentApi/UpdateFile?fileId=' + file.contentItemId, JSON.stringify(file, this.reduceOwnerReferences))
    },

    deleteFile(file) {
        return Api.delete('/ContentApi/DeleteFile?fileId=' + file.contentItemId)
    },

    downloadFile(file) {
        return Api.get('/ContentApi/GetFileData?fileId=' + file.contentItemId)
    }
}
import Api from '../api'

export default {
    getPageAudits(input) {
        if (!input) {
            input = {}
        }

        let url = new URL(`${baseUrl}/PageAuditApi/GetPageAudits`);

        if (input.pageAuditId) {
            url.searchParams.append('pageAuditId', input.pageAuditId);
        }

        if (input.from) {
            url.searchParams.append('from', input.from);
        }

        if (input.to) {
            url.searchParams.append('to', input.to);
        }

        if (input.page) {
            url.searchParams.append('page', input.page);
        }

        if (input.pageSize) {
            url.searchParams.append('pageSize', input.pageSize);
        }

        if (input.search) {
            url.searchParams.append('search', input.search);
        }

        return Api.get(url.href);
    }
}
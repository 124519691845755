import { FileCategoryClientModel } from './file-category-client-model'
import { FileModel } from './file-model'

export class FileCategoryModel {
    selected = false

    constructor(category, categoryList, rootClient) {
        this.owner = categoryList

        this.clients = []
        this.contentItems = []

        if (category) {
            this.fileCategoryId = category.fileCategoryId
            this.categoryName = category.categoryName
            this.description = category.description
            this.availableToEveryone = category.availableToEveryone

            if (category.clients) {
                category.clients.forEach(f => {
                    var client = rootClient ? rootClient.getContactById(f.clientId) : null

                    this.clients.push(new FileCategoryClientModel(f, client))
                })
            }

            if (category.contentItems) {
                category.contentItems.forEach(f => this.contentItems.push(new FileModel(f, this)))
            }

            this.sortFiles()

            if (rootClient) {
                this.sortFileCategoryClients()
            }
        }
        else {
            this.fileCategoryId = '00000000-0000-0000-0000-000000000000'
            this.categoryName = null
            this.description = null
            this.availableToEveryone = false
        }
    }

    nameIsUnique(categoryName) {
        return !this.owner.hasCategoryName(categoryName, this.fileCategoryId)
    }

    addFile(file) {
        this.contentItems.push(new FileModel(file, this))

        this.sortFiles()
    }

    removeFile(fileId) {
        this.contentItems = this.contentItems.filter(f => f.contentItemId !== fileId)
    }

    getFile(fileId) {
        return this.contentItems.filter(f => f.contentItemId === fileId)[0]
    }

    addClients(clients) {
        clients.forEach(client => {
            if (!this.clients.find(f => f.clientId === client.clientId)) {
                this.clients.push(new FileCategoryClientModel(null, client, this))
            }
        })

        this.sortFileCategoryClients()
    }

    removeClient(clientId) {
        this.clients = this.clients.filter(f => f.clientId !== clientId)
    }

    sortFiles() {
        this.contentItems = this.contentItems.slice(0).sort((a, b) => {
            if (a.contentItemTypeId !== b.contentItemTypeId) {
                return a.contentItemTypeId < b.contentItemTypeId
            }
            if (a.contentItemTypeId === 0)
                return a.attributeValues.fileName.localeCompare(b.attributeValues.fileName)
            if (a.contentItemTypeId === 1)
                return a.attributeValues.url.localeCompare(b.attributeValues.url)
        })
    }

    sortFileCategoryClients() {
        this.clients = this.clients.slice(0).sort((a, b) => a.client.contactName.localeCompare(b.client.contactName))
    }

    hasFileName(fileName, contentItemId) {
        var checkName = (fileName || '').toLowerCase()
        return this.contentItems.some(f => (!contentItemId || f.contentItemId !== contentItemId) && (f.attributeValues.fileName || '').toLowerCase() === checkName)
    }

    get hasFiles() {
        return this.contentItems.length > 0
    }

    updateValues(category) {
        this.categoryName = category.categoryName
        this.description = category.description
        this.availableToEveryone = category.availableToEveryone

        this.clients = []

        if (category.clients) {
            category.clients.forEach(f => {
                this.clients.push(new FileCategoryClientModel(f))
            })
        }
    }
}
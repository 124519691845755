import { ApiFactory } from '@/api/api-factory'

const eivApi = ApiFactory.get('eiv')

import * as datetimeHelpers from '@/utilities/datetime'

export const EivSessionStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        sessionWarningPeriodLength: 60,
        sessionTimeToEnd: null,
        sessionRemainingTime: 0,
        eivId: null
    },

    actions: {

        async resetCandidateExpiryTime({ state, commit, dispatch }, eivId) {
            if (!state.eivId) {
                commit('SET_EIV_ID', eivId)
            }
            const response = await eivApi.updateCandidateExpiryTime(eivId)
            if (response && response.data) {
                dispatch('getExpiryTime', eivId)
            }
        },

        async getExpiryTime({ commit, dispatch }, eivId) {
            const response = await eivApi.getCandidateExpiryStatus(eivId)
            const expiryTime = response.data.EviteExpiryDateUtc
            commit('SET_END_TIME', Date.parse(expiryTime)) // Converting to epoch time
            dispatch('sessionStartTimer')
        },

        async sessionStartTimer({ state, commit }) {

            setInterval(() => {

                const timeDiff = datetimeHelpers.dateDiffSeconds(state.sessionTimeToEnd)
                commit('UPDATE_TIME_LEFT', timeDiff)

            }, 5000)
        },

        endSession() {
            window.location.reload() // Reload to redirect to expired session page. Unless there's a URL to route it to?
        },

        async logError({ commit, state }, error) {

            const response = await sessionApi.log(error);

            return response.data;
        },

    },

    getters: {

        getShowRedirect(state) {
            return state.sessionRemainingTime <= 0
        },

        getShowWarning(state) {
            return state.sessionRemainingTime <= state.sessionWarningPeriodLength
        }
    },

    mutations: {

        SET_EIV_ID(state, payload) {
            state.eivId = payload
        },

        SET_END_TIME(state, payload) {
            state.sessionTimeToEnd = payload
        },

        UPDATE_TIME_LEFT(state, payload) {
            state.sessionRemainingTime = payload
        }

    }
}
import { LandingModel } from '@/models/landing/landing-model'

import { ApiFactory } from '@/api/api-factory'

const publicApi = ApiFactory.get('public')

export const PublicStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        landingLoading: false,
        landingLoaded: false,
        landing: null
    },

    actions: {
        async getLanding({ commit, dispatch, state }, id) {
            commit('SET_LANDING_LOADING', true)

            let response = await publicApi.getLanding(id)

            if (response.data && (Object.keys(response.data).length !== 0)) {
                commit('SET_LANDING', response.data)

                if (state.landing && state.landing.whiteLabel) {
                    dispatch('session/setWhiteLabel', state.landing.whiteLabel, { root: true })
                }
            }
            else {
                commit('SET_LANDING', null)
            }

            commit('SET_LANDING_LOADING', false)
            commit('SET_LANDING_LOADED', true)

            dispatch('headerbar/refreshHeaderBarLinks', null, { root: true })

            return state.landing
        }
    },

    getters: {
        isLoginEnabled(state) {
            return !state.landingLoading && (!state.landing || state.landing.contactLanding.features['Login'])
        }
    },

    mutations: {
        SET_LANDING_LOADING(state, loading) {
            state.landingLoading = loading
        },

        SET_LANDING_LOADED(state, loaded) {
            state.landingLoaded = loaded
        },

        SET_LANDING(state, landing) {
            state.landing = landing ? new LandingModel(landing) : null
        }
    }
}
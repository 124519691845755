import { ApiFactory } from '@/api/api-factory'
import EmailTemplateDisplayModel from '@/models/email-templates/email-template-display-model'

const api = ApiFactory.get('emailTemplates')

export const EmailTemplatesStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        emailTemplates: []
    },

    actions: {
        async getEmailTemplates({ dispatch, commit, state }) {
            let response = await api.getProcessorEmailTemplates()
            if (response.data) {
                var templates = response.data.map(t => new EmailTemplateDisplayModel(t))
                commit('SET_EMAIL_TEMPLATES', templates)
            } 
        },

        async editEmailTemplate({ dispatch, state }, template ) {
            let response = await api.editProcessorEmailTemplate(template)
            if (response.data != false) {
                await dispatch('getEmailTemplates')
            }
            return response.data
        },

        async deleteEmailTemplate({ dispatch, state }, templateId) {
            let response = await api.deleteProcessorEmailTemplate(templateId)
            if (response.data != false) {
                await dispatch('getEmailTemplates')
            }
            return response.data
        }

    },

    mutations: {
        SET_EMAIL_TEMPLATES(state, templates) {
            state.emailTemplates = templates
        }
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/stores/store'

import { RouteModel } from '@/models/routes/route-model'

import { merge } from 'lodash'

Vue.use(VueRouter)

const appFeature = await store.dispatch('feature/initApp')

let routes = []

appFeature.meta.routes.forEach(r => routes.push(new RouteModel(r)))

const router = 
    window.useVueRouter ?
        new VueRouter({
            base: '/v/',
            mode: 'history',
            routes
        }) : null

if (router) {
    router.beforeEach(async (to, from, next) => {
        const pageAudit = { 
            dateTime: new Date(), 
            url: to.path, 
            query: window.location.search,
            data: to.query && Object.keys(to.query).length > 0 ? JSON.stringify(to.query) : null,
            details: null
        }

        await store.dispatch('session/startPageAudit', pageAudit)

        const isAuthenticated = store.getters['session/isAuthenticated']

        await store.dispatch('session/sessionStartTimer')

        for (var i = 0; i < to.matched.length; i++) {
            const route = to.matched[i]

            if (route.meta && route.meta.feature) {
                const feature = await store.dispatch('feature/getFeature', route.meta.feature)

                route.meta = merge(route.meta, feature.meta)
            }
        }

        const nearestWithFeature = to.matched.slice().reverse().find(r => r.meta && r.meta.feature)

        if (nearestWithFeature) {
            document.title = nearestWithFeature.meta.feature
        }

        const lowerQuery = {};

        for (const key in to.query) {
            lowerQuery[key.toLowerCase()] = to.query[key];
        }

        if (lowerQuery.whitelabelid || lowerQuery.customerlandingid) {
            await store.dispatch('session/setWhiteLabelById', { whiteLabelId: lowerQuery.whitelabelid, customerLandingId: lowerQuery.customerlandingid })
        }

        if (to.matched.some(route => route.meta.headerBar)) {
            let [linkRoute] = to.matched.filter(route => route.meta.headerBar).slice(-1)

            await store.dispatch('headerbar/setHeaderBarLinks', linkRoute.meta.headerBar)
        }
        else {
            await store.dispatch('headerbar/setHeaderBarLinks', null)
        }

        //if (to.meta.crumbs) {
        //    await store.dispatch('bread/setCrumbs', to.meta.crumbs)
        //}
        //else {
            await store.dispatch('bread/setCrumbs', null)
        //}

        if (to.matched.some(route => route.meta.sidebar)) {
            let [linkRoute] = to.matched.filter(route => route.meta.sidebar).slice(-1)

            await store.dispatch('sidebar/setSideBarLinks', linkRoute.meta.sidebar)
        }
        else {
            await store.dispatch('sidebar/setSideBarLinks', null)
        }

        await store.dispatch('session/getIsSiteOffline')

        if (to.matched.some(route => route.meta.authenticationRequired)) {
            if (!isAuthenticated) {
                store.dispatch('session/login')
            }
            else {
                if (
                    !(to.matched.some(route => route.meta.roles) || to.matched.some(route => route.meta.permissions))
                    ||
                    to.matched.some(
                        route => route.meta.roles && store.getters['session/isInRoles'](route.meta.roles))
                    ||
                    to.matched.some(
                        route => route.meta.permissions && store.getters['session/hasPermission'](route.meta.permissions))
                ) {
                    store.dispatch('session/setIsLoading', true)

                    next()
                }
                else {
                    next({ path: "/" })
                }
            }
        }
        else {
            store.dispatch('session/setIsLoading', true)

            next()
        }
    });

    router.afterEach(async (to, from) => {
        store.dispatch('session/setIsLoading', false)
        await store.dispatch('session/completePageAudit')
    });
}

export default router

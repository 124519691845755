import { ClientLiveLandingGroupPackageModel } from './client-live-landing-group-package-model'

export class ClientLiveLandingGroupModel {
	id = null

	index = 0

	isEnabled = true

	enableJurisdiction = false

	resources = { }

	packages = []

	positions = null

	constructor(entity) {
		this.id = entity.id
		this.index = entity.index
		this.isEnabled = entity.isEnabled

		this.enableJurisdiction = entity.enableJurisdiction

		this.resources = entity.resources

		if (entity.packages) {
			entity.packages.filter(p => p.isEnabled).sort((a, b) => a.index - b.index).forEach(e => {
				this.packages.push(new ClientLiveLandingGroupPackageModel(e, this))
			})
		}

		this.positions = entity.positions
	}
}
export const BreadStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        crumbs: null
    },

    actions: {
        async setCrumbs({ commit }, crumbs) {
            commit('SET_CRUMBS', crumbs)
        }
    },

    getters: {
        hasCrumbs(state) {
            return state.crumbs
        }
    },

    mutations: {
        SET_CRUMBS(state, crumbs) {
            state.crumbs = crumbs
        }
    }
}
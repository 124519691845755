<template>
    <div>
        <loading-component v-if="loadingSupported"
                           :active.sync="isLoading"
                           :can-cancel="false"
                           :is-full-page="true">
        </loading-component>
        <dev-tools id="devtools" v-if="isDevelopment"></dev-tools>
        <router-view></router-view>
    </div>
</template>

<script>
    import Vue from 'vue'

    import { mapState, mapGetters, mapActions } from 'vuex'

    import LoadingComponent from 'vue-loading-overlay'

    import '../create-nonce.js';
    import 'vue-loading-overlay/dist/vue-loading.css'

    import DevTools from './components/dev-tools'


    export default {
        components: {
            LoadingComponent,
            DevTools
        },

        methods: {
            ...mapActions('session', ['initSession']),

            ...mapActions('resource', ['initResource']),

            setBackground() {
                document.body.style.backgroundImage = !this.isProduction && !this.hideBackground ? `url('/content/images/${this.environment}EnvBackground.png')` : ''
                document.body.style.backgroundRepeat = !this.isProduction && !this.hideBackground ? 'repeat-x repeat-y' : ''

                document.body.style.display = ''
            }
        },

        computed: {
            ...mapState('session', ['hideBackground'])
        },

        watch: {
            hideBackground() {
                this.setBackground()
            }
        },

        data() {
            return {
                loadingSupported: !window.isIE
            }
        },

        created() {
            this.setBackground()
        }
    }
</script>

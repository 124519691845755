var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "nav-language ml-2" },
    [
      _c(
        "b-nav-item-dropdown",
        {
          attrs: {
            id: "nav-item-language",
            text: _vm.$t("HeaderBar.Language"),
            right: ""
          }
        },
        _vm._l(_vm.locales, function(locale) {
          return _c(
            "b-dropdown-item",
            {
              key: locale.id,
              attrs: { id: "nav-item-" + locale.id },
              on: {
                click: function($event) {
                  return _vm.setLocale(locale.id)
                }
              }
            },
            [_vm._v(_vm._s(locale.resource))]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
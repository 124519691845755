<template>
    <div>
        <b-button v-b-modal.dev-modal :title="$t('DevTools.DevTools')" variant="light" class="btn-icon">
            <v-icon icon="tools"></v-icon>
        </b-button>
        <b-modal id="dev-modal"
                 ref="dev-modal"
                 :title="$t('DevTools.DevTools')"
                 centered
                 hide-header-close
                 ok-only>
            <b-row>
                <b-col>
                    <b-form-checkbox id="checkbox-autosessionchecks" v-model="isAutoResetTimeoutNextTick">{{ $t('DevTools.AutomateSessionChecks') }}</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-checkbox id="checkbox-showbackground" v-model="isShowingBackground">{{ $t('DevTools.ShowBackground') }}</b-form-checkbox>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        name: "dev-tools",

        computed: {
            ...mapState('session', ['autoResetTimeoutNextTick', 'hideBackground'])
        },

        methods: {
            ...mapActions('session', ['setAutoResetTimeoutNextTick', 'setHideBackground']),
        },

        watch: {
            isShowingBackground(val) {
                this.setHideBackground(!val)
            },

            isAutoResetTimeoutNextTick(val) {
                this.setAutoResetTimeoutNextTick(val)
            },

            autoResetTimeoutNextTick(val) {
                this.isAutoResetTimeoutNextTick = val
            },

            hideBackground(val) {
                this.isShowingBackground = !val
            }
        },

        data() {
            return {
                isAutoResetTimeoutNextTick: false,
                isShowingBackground: false
            }
        },

        async mounted() {
            this.isAutoResetTimeoutNextTick = this.autoResetTimeoutNextTick
            this.isShowingBackground = !this.hideBackground
        }
    }
</script>

<i18n>
    {
    "en": {
    "DevTools": {
    "DevTools": "Dev Config",
    "AutomateSessionChecks": "Session Never Expires",
    "ShowBackground": "Show Background"
    }
    },
    "fr": {
    "DevTools": {
    "DevTools": "Dev Config",
    "AutomateSessionChecks": "Session Never Expires",
    "ShowBackground": "Show Background"
    }
    }
    }
</i18n>

import { ApiFactory } from '@/api/api-factory';

import EivValidityModel from '@/models/upload-portal/eiv-validity-model'

const eivApi = ApiFactory.get('eiv');

export const UploadPortalStore = {

    namespaced: true,

    state: {
        result: null,
        validityModel: {}
    },

    actions: {
        // Check the status of the EIV, get the response, set response to state, then retrive that state in the component via a getter
        async getEivForCandidateUpload({ commit, state }, eivId) {
            let response = await eivApi.getEivForCandidateUpload(eivId)
            if (response.data) {
                var model = new EivValidityModel(response.data)
                commit('SET_VALIDITY_MODEL', model)
            }
        },

        // Send the file and userVerifiedId to the backend
        async sendCandidateDocument({ commit }, args) {
            let response = await eivApi.uploadCandidateProvidedVSCheck(args.eivId, args.file)
            commit('SET_RESULT', response.data)
            return response.data
        }

    },

    mutations: {
        SET_VALIDITY_MODEL(state, model) {
            state.validityModel = model //suspect
        },
        SET_RESULT(state, result) {
            state.result = result
        },
    }
}

//import { FeatureModel } from '../models/Features/feature-model'

import { ApiFactory } from '@/api/api-factory'

const featureApi = ApiFactory.get('feature')

export const FeatureStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        initialized: false,
        features: {  }
    },

    actions: {
        async initApp({ commit, dispatch, state })
        {
            if (state.initialized) {
                return
            }

            let appFeature

            if (window.initSession.features) {
                window.initSession.features.forEach(featureString => {
                    const feature = JSON.parse(featureString)

                    if (feature.name == 'App') {
                        appFeature = feature
                    }

                    if (feature.resources) {
                        dispatch('resource/mergeComponentResources', { name: feature.name, resources: feature.resources }, { root: true })
                    }

                    commit('LOAD_FEATURE', feature)
                })
            } else {
                appFeature = await dispatch('getFeature', 'App')

                await dispatch('getFeature', 'HeaderBar')
            }

            dispatch('resource/initLocales', appFeature.meta.locales, { root: true })

            commit('SET_INITIALIZED', true)

            return appFeature
        },

        async getFeature({ state, commit, dispatch }, name) {
            if (state.features[name]) {
                return state.features[name]
            }

            let response = await featureApi.getFeature(name)

            let feature = response.data

            if (feature && (Object.keys(feature).length !== 0)) {
                commit('LOAD_FEATURE', feature)

                if (feature.resources) {
                    dispatch('resource/mergeComponentResources', { name, resources: feature.resources }, { root: true })
                }

                return feature
            }

            return null
        }
    },

    mutations: {
        SET_INITIALIZED(state, initialized) {
            state.initialized = initialized
        },

        LOAD_FEATURE(state, feature) {
            state.features[feature.name] = feature
        }
    }
}
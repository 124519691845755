import { ApiFactory } from '@/api/api-factory'

import { ErrorLogListModel } from '@/models/error-logs/error-log-list-model'

const errorLogApi = ApiFactory.get('errorLog')

export const ErrorLogStore = {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,

    state: {
        startUtc: null,
        endUtc: null,
        totalRecordCount: 0,
        page: 0,
        pageSize: 100,
        pageCount: 0,
        search: null,
        errorLogs: null
    },

    actions: {
        async loadErrorLogs({ commit }, input) {
            let response = await errorLogApi.getErrors(input)

            if (response.data) {
                commit('SET_ERRORS', response.data)
            }
            else {
                commit('SET_ERRORS', null)
            }
        },
    },

    mutations: {
        SET_ERRORS(state, data) {
            if (data) {
                state.startUtc = data.startUtc;
                state.endUtc = data.endUtc;
                state.totalRecordCount = data.totalRecordCount;
                state.page = data.page;
                state.pageSize = data.pageSize;
                state.pageCount = data.pageCount;
                state.search = data.search;

                state.errorLogs = new ErrorLogListModel(data.records);
            } else {
                state.startUtc = null;
                state.endUtc = null;
                state.totalRecordCount = 0;
                state.page = 0;
                state.pageSize = 100;
                state.pageCount = 0;
                state.search = null;

                state.errorLogs = null;
            }
        },
    }
}

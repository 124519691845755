export class FileClientModel {
    selected = false

    constructor(fileClient, client, file) {
        if (fileClient) {
            this.fileClientId = fileClient.fileClientId
            this.fileId = fileClient.fileId
            this.clientId = fileClient.clientId
        }
        else {
            this.fileClientId = '00000000-0000-0000-0000-000000000000'
            this.fileId = file.contentItemId
            this.clientId = client.contactId
        }

        if (client) {
            this.client = client
        }
        else {
            this.client = { contactName: 'Unknown', userId: 'Unknown' }
        }
    }
}
import Api from '../api'

export default {
    checkSession() {
        return Api.get('/AccountApi/CheckSession')
    },

    login() {
        window.location = window.baseUrl + '/Account/Login'
    },

    logoff() {
        window.location = window.baseUrl + '/Account/Logoff'
    },

    shadow (contactId) {
        window.location = window.baseUrl + `/Account/Shadow/${contactId}`
    },

    unshadow() {
        window.location = window.baseUrl + '/Account/UnShadow'
    },

    getIsSiteOffline() {
        return Api.get('/SessionApi/GetIsSiteOffline')
    },

    goOffline() {
        window.location = window.baseUrl + '/content/offline.html'
    },

    log(message) {
        console.error(message)

        return Api.post('/SessionApi/Log', message)
    },

    startPageAudit(audit) {
        return Api.post('/SessionApi/StartVuePageAudit', JSON.stringify(audit))
    },

    completePageAudit(audit) {
        return Api.post('/SessionApi/CompleteVuePageAudit', JSON.stringify(audit))
    },

    getLocale() {
        return Api.get('/ResourceApi/GetCurrentLanguage')
    },

    setLocale(locale, reload) {
        if (reload) {
            window.location = window.baseUrl + `/Public/SetCulture/${locale}-CA`
        }
        else {
            return Api.get('/SessionApi/SetLocale/' + locale)
        }
    },

    getComponentResources(componentName) {
        return Api.get(`/ResourceApi/GetComponentResources?componentName=${componentName}`)
    },

    setWhiteLabel(whiteLabelId, customerLandingId) {
        if (customerLandingId) {
            return Api.get(`/SessionApi/GetVueWhiteLabelTheme?customerLandingId=${customerLandingId}`)
        }

        return Api.get(`/SessionApi/GetVueWhiteLabelTheme?whiteLabelId=${whiteLabelId}`)
    }
}

import { FileCategoryModel } from './file-category-model'

export class FileCategoriesListModel {
    fileCategories = []

    constructor(categories) {
        if (!categories) {
            return
        }

        categories.forEach(category => this.fileCategories.push(new FileCategoryModel(category, this)))

        this.sort()
    }

    addCategory(category) {
        this.fileCategories.push(new FileCategoryModel(category, this))

        this.sort()
    }

    removeCategory(fileCategoryId) {
        this.fileCategories = this.fileCategories.filter(f => f.fileCategoryId !== fileCategoryId)
    }

    sort() {
        this.fileCategories = this.fileCategories.slice(0).sort((a, b) => a.categoryName.localeCompare(b.categoryName))
    }

    get hasCategories() {
        return this.fileCategories.length > 0
    }

    hasCategoryName(categoryName, fileCategoryId) {
        var checkName = (categoryName || '').toLowerCase()
        return this.fileCategories.some(f => (!fileCategoryId || f.fileCategoryId !== fileCategoryId) && (f.categoryName || '').toLowerCase() === checkName)
    }

    getFileCategory(fileCategoryId) {
        return this.fileCategories.filter(f => f.fileCategoryId === fileCategoryId)[0]
    }
}
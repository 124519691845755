import { ErrorLogModel } from '../error-logs/error-log-model'

export class PageAuditModel {
    errorLogs;

    constructor(pageAudit) {
        this.id = pageAudit.id;

        this.dateTime = new Date(pageAudit.dateTime);
        this.dateString = this.dateTime.toLocaleDateString();
        this.timeString = this.dateTime.toLocaleTimeString();

        this.durationMS = pageAudit.durationMS;
        this.url = pageAudit.url;
        this.query = pageAudit.query;
        this.machineName = pageAudit.machineName;
        this.userName = pageAudit.userName;
        this.ipAddress = pageAudit.ipAddress;
        this.sessionId = pageAudit.sessionId;
        this.responseCode = pageAudit.responseCode;
        this.data = pageAudit.data;
        this.controller = pageAudit.controller;
        this.action = pageAudit.action;

        if (pageAudit.errorLogs) {
            this.errorLogs = [];

            pageAudit.errorLogs.forEach(errorLog => this.errorLogs.push(new ErrorLogModel(errorLog)))
        }
    }
}

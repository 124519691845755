import { FileClientModel } from './file-client-model'
import { FileDataModel } from './file-data-model'

export class FileModel {
    selected = false

    constructor(file, fileCategory, newContentItemTypeId, rootClient) {
        this.maxFileNameLength = 255

        this.owner = fileCategory

        this.clients = []
        this.fileDatas = []

        if (file) {
            this.contentItemId = file.contentItemId
            this.description = file.description
            this.attributes = file.attributes
            this.attributeValues = JSON.parse(file.attributes)
            this.splitFileName(this.attributeValues.fileName)
            this.contentItemTypeId = file.contentItemTypeId
            this.fileCategoryId = file.fileCategoryId
            this.availableToEveryone = file.availableToEveryone
            this.isDisabled = file.isDisabled
            this.language = file.language
            this.addedOn = file.addedOn
            this.addedBy = file.addedBy

            if (file.clients) {
                file.clients.forEach(f => {
                    var client = rootClient ? rootClient.getContactById(f.clientId) : null

                    this.clients.push(new FileClientModel(f, client))
                })
            }

            if (file.fileDatas) {
                file.fileDatas.forEach(f => this.fileDatas.push(new FileDataModel(f)))
            }

            if (rootClient) {
                this.sortFileClients()
            }
        }
        else {
            this.contentItemId = '00000000-0000-0000-0000-000000000000'
            this.description = null
            this.attributes = null
            this.attributeValues = { size: 0, type: '', fileName: ''}
            this.splitFileName(null)
            this.contentItemTypeId = newContentItemTypeId
            this.fileCategoryId = fileCategory.fileCategoryId
            this.availableToEveryone = false
            this.isDisabled = false
            this.language = null
            this.addedOn = null
            this.addedBy = null
        }

        this.tooLarge = false
    }

    nameIsUnique(name) {
        return !this.owner.hasFileName(name, this.contentItemId)
    }

    replaceFileData(data) {
        this.fileDatas = []

        var fileData = new FileDataModel()

        fileData.contentItemId = this.contentItemId
        fileData.data = data

        this.fileDatas.push(fileData)

        this.attributes = JSON.stringify(this.attributeValues)
    }

    addClients(clients) {
        clients.forEach(client => {
            if (!this.clients.find(f => f.clientId === client.clientId)) {
                this.clients.push(new FileClientModel(null, client, this))
            }
        })

        this.sortFileClients()
    }

    removeClient(clientId) {
        this.clients = this.clients.filter(f => f.clientId !== clientId)
    }

    sortFileClients() {
        this.clients = this.clients.slice(0).sort((a, b) => a.client.contactName.localeCompare(b.client.contactName))
    }

    finalizeFileName() {
        this.attributeValues.fileName = this.fileNameNoExtension ? this.fileNameNoExtension + (this.fileExtension || '') : null
    }

    splitFileName(fileName) {
        this.maxFileNameLength = 255

        if (fileName && fileName.lastIndexOf('.') !== -1) {
            this.fileExtension = fileName.substr(fileName.lastIndexOf('.'))
            this.fileNameNoExtension = fileName.substr(0, fileName.lastIndexOf('.'))

            this.maxFileNameLength = this.maxFileNameLength - this.fileExtension.length
        }
        else {
            this.fileNameNoExtension = fileName
            this.fileExtension = null
        }

        if (this.fileNameNoExtension > this.maxFileNameLength) {
            this.fileNameNoExtension = this.fileNameNoExtension.substr(0, this.maxFileNameLength)
        }

        this.finalizeFileName()
    }

    updateValues(file) {
        this.attributes = file.attributes
        this.attributeValues = file.attributeValues
        this.description = file.description
        this.availableToEveryone = file.availableToEveryone
        this.isDisabled = file.isDisabled
        this.language = file.language
        this.contentItemTypeId = file.contentItemTypeId

        this.clients = []
        this.fileDatas = []

        if (file.clients) {
            file.clients.forEach(f => {
                this.clients.push(new FileClientModel(f))
            })
        }
    }
}
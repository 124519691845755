import { ClientApiPushModel } from './client-api-push-model'

import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export class ClientApiModel {
	id = null

	push = null

	createdDate = null

	createdBy = null

	modifiedDate = null

	modifiedBy = null

	constructor(args) {
		const entity = args ? args.entity : null

		if (entity) {
			this.id = entity.id

			this.push = new ClientApiPushModel({ entity: entity.push })

			this.createdDate = entity.createdDate
			this.createdBy = entity.createdBy
			this.modifiedDate = entity.modifiedDate
			this.modifiedBy = entity.modifiedBy
		}
		else {
			this.id = uuidv4()

			this.push = new ClientApiPushModel()

			this.createdDate = new Date().toISOString()
			this.createdBy = store.state.session.auth.identityId
		}
	}
}
import { ClientApiModel } from './client-api-model'
import { ClientLandingModel } from './client-landing-model'

export class ClientAttributesModel {
	contactId = null

	api = null

	landing = null

	constructor(entity) {
		if (entity) {
			this.contactId = entity.contactId

			if (entity.api) {
				this.api = new ClientApiModel({ entity: entity.api })
			}

			if (entity.landing) {
				this.landing = new ClientLandingModel({ entity: entity.landing })
			}
		}
	}

	addApi() {
		if (this.api) {
			return
		}

		this.api = new ClientApiModel()
	}

	deleteApi() {
		if (!this.api) {
			return
		}

		this.api = null
	}

	addLanding(name) {
		if (this.landing) {
			return
		}

		this.landing = new ClientLandingModel({ name })
	}

	deleteLanding() {
		if (!this.landing) {
			return
		}

		this.landing = null
	}
}
<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <b-row v-if="!!$slots.header" class="text-center mt-5">
                    <b-col>
                        <slot name="header"></slot>
                    </b-col>
                </b-row>

                <b-row v-if="!!$slots.default" class="text-center mt-4 mb-5">
                    <b-col>
                        <slot></slot>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    export default {
        name: 'empty-group'
    }

</script>
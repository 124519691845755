var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "text-center mt-5" },
        [
          _c(
            "b-col",
            [
              _c("v-icon", {
                staticClass: "text-secondary",
                staticStyle: { width: "48px", height: "48px" },
                attrs: { icon: "plug" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "text-center mt-5" },
        [
          _c("b-col", [_c("h4", [_vm._v(_vm._s(_vm.$t("Standard.Disabled")))])])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "text-center mt-4" },
        [
          _c("b-col", [
            _c(
              "label",
              {
                staticClass: "text-secondary mt-2",
                staticStyle: { width: "700px" }
              },
              [_vm._v(_vm._s(_vm.$t("Standard.DisabledMessage")))]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
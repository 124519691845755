import { ClientLandingQuestionOptionModel } from './client-landing-question-option-model'

import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export class ClientLandingQuestionModel {
	id = null

	index = 0

	isEnabled = true

	resources = { }

	questionType = 'SelectOne'

	options = []

	constructor(args) {
		// is undefined when creating first question.
		const entity = args ? args.entity : null

		if (entity) {
			
			this.id = entity.id
			this.index = entity.index
			this.isEnabled = entity.isEnabled

			this.resources = entity.resources

			this.questionType = entity.questionType

			if (entity.options) {
				entity.options.sort((a, b) => a.index - b.index).forEach(e => {
					this.options.push(new ClientLandingQuestionOptionModel({ entity: e }))
				})
			}
		}
		else {
			this.id = uuidv4()

			if (args) {
				this.resources = args.resources
			}
		}

		// Add missing locales
		const locales = store.state.feature.features['App'].meta.locales

		if (locales) {
			locales.forEach(locale => {
				if (!this.resources[locale.id]) {
					this.resources[locale.id] = { 'Question': '', 'Description': '' }
				}
			})
		}
	}

	reIndexOptions() {
		for (let i = 0; i < this.options.length; i++) {
			this.options[i].index = i
		}
	}

	hasOption(id) {
		return this.options.some(f => f.id == id)
	}

	addOption(args) {
		// Args come from the question edit vue comp (addOption method).
		this.options.push(new ClientLandingQuestionOptionModel(args))

		this.reIndexOptions()
	}

	deleteOption(id) {
		this.options = this.options.filter(f => f.id !== id)
	}

	removeDeletedQuestionFromNextAction(questionId) {
		this.options.forEach((o) => {
			if (o.nextAction == questionId) {
				o.nextAction = null
			}
        })
    }
}
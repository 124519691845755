import store from '@/stores/store'

export default class EivValidityModel {

    isValidEivId = true
    isExpired = false
    isPendingCandidateUpload = false
    isComplete = false
    resources = {}

    constructor(request) {

        // Add all locales
        const locales = store.state.feature.features['App'].meta.locales

        if (locales) {
            locales.forEach(locale => {
                if (!this.resources[locale.id]) {
                    this.resources[locale.id] = { 'AuthorizeToRelease': '' }
                }
            })
        }

        if (request) {
            this.isValidEivId = request.isValidEivId
            this.isExpired = request.isExpired
            this.isPendingCandidateUpload = request.isPendingCandidateUpload
            this.isComplete = request.isComplete

            request.ResourceItems.forEach((item, index) => {
                Object.keys(item.Values).forEach((key) => {
                    if (this.resources[key]) {
                        this.resources[key][item.Name] = item.Values[key]
                    }
                });
            });		
        }
    }
}
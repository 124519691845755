var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoadingTheme
    ? _c("b-img", {
        ref: "navLogo",
        attrs: {
          id: "nav-logo",
          src:
            "/Content/" +
            (_vm.currentLocale ? _vm.currentLocale : "en") +
            "/images/WhiteLabel/" +
            _vm.theme.logos.logo
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-navbar-nav class="nav-main ml-auto">
        <b-nav-item v-if="headerBarLinks && isNavItemVisible(link.isVisible)"
                    v-for="link in headerBarLinks"
                    :id="'header-bar-link-' + link.id"
                    :href="link.href"
                    :to="useVueRouter ? link.to : '/v' + link.to" :class="classObject(link)" append>
            <v-icon v-if="link.warning" icon="exclamation-triangle" class="text-danger"></v-icon>
            {{ link.resource ? $t(link.resource) : $t('HeaderBar.' + link.id) }}
            <b-badge v-if="link.badge && badgeValue(link.badge) > 0" variant="light">{{ badgeValue(link.badge) }}</b-badge>
        </b-nav-item>
    </b-navbar-nav>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    import { get } from 'lodash'

    const headerBarStoreName = 'headerbar'

    import { BNavItem, BNavbarNav } from 'bootstrap-vue'

    export default {
        name: 'header-navbar-main',

        components: {
            BNavItem,
            BNavbarNav
        },

        data() {
            return {
                locationPath: window.location.pathname.toLowerCase()
            }
        },

        computed: {
            ...mapState('session', ['menu']),

            ...mapState(headerBarStoreName, ['headerBarLinks', 'headerBarFeatures']),

            ...mapGetters(headerBarStoreName, ['hasHeaderBarLinks']),
        },

        methods: {
            classObject(link) {
                if (link.href) {
                    return {
                        'router-link-active': this.locationPath.startsWith(link.activeHref ? link.activeHref : link.href)
                    }
                }

                return ''
            },

            isNavItemVisible(path) {
                if (!path)
                    return true

                if (!Array.isArray(path)) {
                    return this.getValue(path)
                }

                return path.every(p => this.getValue(p))
            },

            getValue(path) {
                if (path.startsWith('!')) {
                    return !get(this, path.replace('!', ''))
                }

                return get(this, path)
            },

            badgeValue(path) {
                if (!path)
                    return 0

                return get(this, path)
            }
        }
    }
</script>
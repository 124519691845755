var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingSupported
        ? _c("loading-component", {
            attrs: {
              active: _vm.isLoading,
              "can-cancel": false,
              "is-full-page": true
            },
            on: {
              "update:active": function($event) {
                _vm.isLoading = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.auth.isAuthenticated ? _c("session-timeout") : _vm._e(),
      _vm._v(" "),
      _vm.isDevelopment
        ? _c("dev-tools", { attrs: { id: "devtools" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.useVueRouter
            ? _c(
                "b-container",
                [_c("router-view", { staticClass: "pt-4 pb-4" })],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { v4 as uuidv4 } from 'uuid'

import store from '@/stores/store'

export default class EmailTemplateDisplayModel {
	id = null

	resources = {}

	constructor(template) {

		// Add all locales
		const locales = store.state.feature.features['App'].meta.locales

		if (locales) {
			locales.forEach(locale => {
				if (!this.resources[locale.id]) {
					this.resources[locale.id] = { 'Name': '', 'Content': '' }
				}
			})
		}

		// Set values from incoming template
		if (template != null) {
			this.id = template.Id

			template.ResourceItems.forEach((item, index) => {
				Object.keys(item.Values).forEach((key) => {
					if (this.resources[key]) {
						this.resources[key][item.Name] = item.Values[key]
					}
				});
			});			
		}
		else {
			this.id = 0
		}
	}
}